import { createWebHistory, createRouter } from 'vue-router';

import Invite from './pages/Invite.vue';
import Users from './pages/Users.vue';
import Login from './pages/Login.vue';
import ForgotPassword from './pages/ForgotPassword.vue';
import Register from './pages/Register.vue';
import ResetPassword from './pages/ResetPassword.vue';
import NotFound from './pages/404.vue';
import Applications from '@/pages/Applications';
import AuthLayout from '@/layouts/AuthLayout';
import MainLayout from '@/layouts/MainLayout';
import MainCustomerLayout from '@/layouts/MainCustomerLayout';
import LoginCustomer from '@/pages/LoginCustomer';
import Application from '@/pages/Application';
import Products from '@/pages/Products';
import Forms from '@/pages/Forms';
import Orders from '@/pages/Orders';
import Quote from '@/pages/Quote';
import Merchants from '@/pages/Merchants';
import Documents from '@/pages/Documents';

const routes = [
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '/',
        name: 'Applications',
        component: Applications,
      },
      {
        path: 'merchants',
        name: 'Merchants',
        component: Merchants,
      },
      {
        path: 'invite',
        name: 'Invite',
        component: Invite,
      },
      {
        path: 'users',
        name: 'Users',
        component: Users,
      },
      {
        path: 'products',
        name: 'Products',
        component: Products,
      },
      {
        path: 'forms',
        name: 'Forms',
        component: Forms,
      },
      {
        path: 'orders',
        name: 'Orders',
        component: Orders,
      },
      {
        path: 'order/:orderId/quote',
        name: 'Quote',
        component: Quote,
      },
      {
        path: 'documents',
        name: 'Documents',
        component: Documents,
      },
    ],
  },
  {
    path: '/',
    component: AuthLayout,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login,
      },
      {
        path: 'login/customer',
        name: 'LoginCustomer',
        component: LoginCustomer,
      },
      {
        path: 'register/:token',
        name: 'Register',
        component: Register,
      },
      {
        path: 'reset-password/:token',
        name: 'ResetPassword',
        component: ResetPassword,
      },
      {
        path: 'forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
      },
    ],
  },
  {
    path: '/',
    component: MainCustomerLayout,
    children: [
      {
        path: '/application',
        component: Application,
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    'Login',
    'Register',
    'ResetPassword',
    'ForgotPassword',
    'LoginCustomer',
  ];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem('user');
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;
