<template>
    <Dialog
        id="create-account-modal"
        header="Create Account and start application"
        v-model:visible="isVisible"
        :breakpoints="{'960px': '75vw', '769px': '95vw'}"
        :style="{width: '40vw'}"
        @hide="close()"
    >
      <template #header>
        <span class="p-dialog-title">Create Account and start application</span>
        <div class="flex">
          <ProgressBar v-if="isLoading" style="position: absolute; top:2px; left: 0px; height: 4px; width: 100%" mode="indeterminate"/>
        </div>
      </template>
      <small v-if="error" class="p-error">Account not created</small>
      <div class="grid m-0 flex flex-1">
            <div class="col-12 px-0 lg:px-2 mb-2 lg:mb-3">
                <div class="step-header">Enter your account information</div>
            </div>
            <div class="col-12 lg:col-6 px-0 lg:px-2">
                <div class="p-field mb-2 flex flex-column">
                    <label for="fullName" class="mb-2">Full Name</label>
                    <InputText
                        id="fullName"
                        placeholder="Full Name"
                        type="text"
                        v-model="data.name"
                        :disabled="isLoading"
                        :class="{'p-invalid': errors.name }"
                        aria-describedby="name_help"
                    />
                  <small id="name_help" v-if="errors.name" class="p-error">{{ errors.name[0] }}</small>
                </div>
            </div>
            <div class="col-12 lg:col-6 px-0 lg:px-2">
                <div class="p-field mb-2 flex flex-column">
                    <label for="password" class="mb-2">Password</label>
                    <Password
                        id="password"
                        type="password"
                        v-model="data.password"
                        :disabled="isLoading"
                        :class="{'p-invalid': errors.password }"
                        aria-describedby="password_help"
                    />
                  <small id="password_help" v-if="errors.password" class="p-error">{{ errors.password[0] }}</small>
                </div>
            </div>
            <div class="col-12 lg:col-6 px-0 lg:px-2">
                <div class="p-field mb-2 flex flex-column">
                    <label for="email" class="mb-2">Email</label>
                    <InputText
                        id="email"
                        type="text"
                        placeholder="Email"
                        v-model="data.email"
                        :disabled="isLoading"
                        :class="{'p-invalid': errors.email }"
                        aria-describedby="email_help"
                    />
                  <small id="email_help" v-if="errors.email" class="p-error">{{ errors.email[0] }}</small>
                </div>
            </div>
            <div class="col-12 lg:col-6 px-0 lg:px-2">
                <div class="p-field mb-2 flex flex-column">
                    <label for="phone" class="mb-2">Phone Number</label>
                  <InputMask
                        id="phone"
                        mask="999-999-9999"
                        :unmask="true"
                        placeholder="___-__-____"
                        type="text"
                        v-model="data.phone"
                        :disabled="isLoading"
                        :class="{'p-invalid': errors.phone }"
                        aria-describedby="phone_help"
                    />
                  <small id="phone_help" v-if="errors.phone" class="p-error">{{ errors.phone[0] }}</small>
                </div>
            </div>
            <div class="col-12 lg:col-6 px-0 lg:px-2">
                <div class="p-field mb-2 flex flex-column">
                    <label for="timezone" class="mb-2">Timezone</label>
                    <Dropdown
                        v-model="data.timezone"
                        :options="timezones"
                        optionLabel="name"
                        optionValue="name"
                        id="timezone"
                        placeholder="Select"
                        :disabled="isLoading"
                        :class="{'p-invalid': errors.timezone }"
                        aria-describedby="timezone_help"
                    />
                  <small id="timezone_help" v-if="errors.timezone" class="p-error">{{ errors.timezone[0] }}</small>
                </div>
            </div>
        </div>
        <template #footer>
            <div class="flex justify-content-end">
                <Button :disabled="isLoading" @click="isVisible = false" label="Cancel"
                        class="p-button-outlined p-button-lg sm:block flex-1 sm:flex-initial"/>
                <Button :disabled="isLoading" @click="start()" label="Start application" class="p-button-lg sm:px-6 flex-1 sm:flex-initial"/>
            </div>
        </template>
    </Dialog>
</template>

<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Dropdown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';
import ApplicationService from "@/services/api-calls/application.service";
import InputMask from 'primevue/inputmask';
import ProgressBar from 'primevue/progressbar';

export default {
  name: 'CreateAccount',
  components: {
    InputText,
    Password,
    Button,
    Dropdown,
    Dialog,
    InputMask,
    ProgressBar
  },
  props: {
    isOpen: Boolean
  },
  updated() {
    this.isVisible = this.isOpen
  },
  methods: {
    close() {
      if (!this.isLoading) {
        this.$emit('close');
      }
    },
    async start() {
      this.isLoading = true;
      this.errors = {};
      this.error = null;
      const result = await ApplicationService.create(this.data);
      if (result.success) {
        this.data = {};
        this.$emit('created', result.application);
      } else {
        this.error = result.error ?? null;
        this.errors = result.errors ?? {};
      }
      this.isLoading = false;
    }
  },
  data() {
    return {
      errors: {},
      error: null,
      isLoading: false,
      data: {},
      timezones: [
        {name: 'America/Los_Angeles'},
        {name: 'America/Denver'},
        {name: 'America/Chicago'},
        {name: 'America/New_York'},
        {name: 'Pacific/Honolulu'},
      ],
      isVisible: false,
    };
  },
};
</script>