<template>
    <div class="flex flex-column">
      <p class="mt-0">{{question.question}}</p>
      <div v-if="'Yes/No' === question.type || ('MultipleChoice-SingleSelect' === question.type && question.answers.length <= 3)" class="flex">
        <div v-for="answer in question.answers" v-bind:key="answer.id" class="p-field-radiobutton mr-4">
          <RadioButton :class="{'p-invalid': errors[questionKey] }" :id="'answer' + questionKey + answer.id" name="city" :value="answer.id" v-model="value"/>
          <label :for="'answer' + questionKey + answer.id">{{answer.answer}}</label>
        </div>
      </div>
      <div v-else-if="'MultipleChoice-SingleSelect' === question.type && question.answers.length > 3" class="flex">
        <Dropdown
            v-model="value"
            :options="question.answers"
            optionLabel="answer"
            optionValue="id"
            :id="'answer' + questionKey"
            placeholder="Select"
            :class="{'p-invalid': errors[questionKey] }"
        />
      </div>
      <div v-else-if="'TimeZone' === question.type" class="flex">
        <Dropdown
            v-model="value"
            :options="timezones"
            optionLabel="name"
            optionValue="name"
            :id="'answer' + questionKey"
            placeholder="Select"
            :class="{'p-invalid': errors[questionKey] }"
        />
      </div>
      <div v-else-if="'24-Hour Time (Hour:Minute)' === question.type" class="flex">
        <div class="grid timepicker">
          <div class="flex align-items-center mr-4">
            <div class="timepicker-option timepicker-option-hr">
              <i class="timepicker-arrow pi pi-chevron-up"/>
              <InputText
                  class="timepicker-input"
                  type="number"
                  max="12"
                  min="1"
                  v-model.number="hour"
                  @input="updateTime()"
                  :class="{'p-invalid': errors[questionKey] }"
              />
              <i class="timepicker-arrow pi pi-chevron-down"/>
            </div>
            <div class="ml-2 mr-2">:</div>
            <div class="timepicker-option timepicker-option-min">
              <i class="timepicker-arrow pi pi-chevron-up"/>
              <InputText
                  class="timepicker-input"
                  type="number"
                  min="0"
                  max="59"
                  v-model.number="minute"
                  @input="updateTime()"
                  :class="{'p-invalid': errors[questionKey] }"
              />
              <i class="timepicker-arrow pi pi-chevron-down"/>
            </div>
          </div>
          <div>
            <SelectButton @click="updateTime()" v-model="time" :options="options"/>
          </div>
        </div>
      </div>
      <div v-else-if="'FreeText' === question.type" class="flex">
        <InputText :class="{'p-invalid': errors[questionKey] }" :id="'answer' + questionKey" type="text" v-model="value"/>
      </div>
      <div v-else-if="'DollarAmount' === question.type">
        <div class="flex mb-2">
          <span class="p-inputgroup-addon"><i class="pi pi-dollar"/></span>
          <InputText :class="{'p-invalid': errors[questionKey] }" :id="'answer' + questionKey" type="number" v-model.number="value"/>
        </div>
      </div>
      <div v-else-if="'Percentage' === question.type" class="flex">
        <div class="flex flex-1 align-items-center">
          <div class="w-full">
            <Slider v-model.number="value"/>
          </div>
        </div>
        <div>{{ value }}%</div>
      </div>
      <div v-else class="flex">
        <small class="p-error">Undefined question type {{ question.type }}</small>
      </div>
      <small v-if="errors[questionKey]" class="p-error">{{ errors[questionKey] }}</small>
      <div v-if="question.children.length">
        <Questions :questions="{area: questionKey, questions: question.children}" :parent-value="value" :errors="errors" />
      </div>
    </div>
</template>
<script>
import RadioButton from 'primevue/radiobutton';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import SelectButton from 'primevue/selectbutton';
import Slider from 'primevue/slider';

export default {
  name: 'Question',
  components: {
    RadioButton,
    Dropdown,
    InputText,
    SelectButton,
    Slider
  },
  mounted() {
    switch (this.question.type) {
      case 'Yes/No' :
      case 'MultipleChoice-SingleSelect' :
        this.question.answers.forEach((answer) => {
          if (answer.selected) {
            this.value = answer.id
          }
        })
        break;
      case 'TimeZone':
      case '24-Hour Time (Hour:Minute)':
        if (this.question.answers[0].selected) {
          this.value = this.question.answers[0].answer
          const timeParts = this.value.split(':');
          if (2 === timeParts.length) {
            let hour = parseInt(timeParts[0])
            let minute = parseInt(timeParts[1])
            let time = 'AM';
            if (hour > 12) {
              time = 'PM';
              hour -= 12;
            }
            this.hour = hour;
            this.minute = minute;
            this.time = time;
          }
        }
        break;
      case 'FreeText':
        if (this.question.answers[0].selected) {
          this.value = this.question.answers[0].answer
        }
        break;
      case 'DollarAmount':
      case 'Percentage':
        if (this.question.answers[0].selected) {
          this.value = parseInt(this.question.answers[0].answer)
        }
        break;
    }
  },
  watch: {
    value() {
      let question = this.question;
      switch (question.type) {
        case 'Yes/No' :
        case 'MultipleChoice-SingleSelect' :
          question.answers.forEach((answer) => {
              if (answer.id === this.value) {
                answer.selected = true
              } else {
                answer.selected = false
              }
          })
          break;
        case 'TimeZone':
        case '24-Hour Time (Hour:Minute)':
        case 'FreeText':
        case 'DollarAmount':
        case 'Percentage':
          question.answers[0].answer = this.value;
          question.answers[0].selected = !!this.value;
          break;
      }
      this.$emit('updateQuestion', {...question});
    },
  },
  props: {
    errors: Object,
    question: Object,
    questionKey: String,
  },
  methods: {
    updateConfiguration(configuration) {
      let question = this.question;
      question.children = configuration
      this.$emit('updateQuestion', {...question});
    },
    updateTime() {
      if (this.hour > 12) {
        this.hour = 1
      }
      if (this.minute > 59) {
        this.minute = 0
      }
      if (this.hour && this.minute > -1 && this.time) {
        let hour = this.time === 'AM' ? this.hour : 12 + this.hour;
        this.value = (hour < 10 ? '0' : '') + hour + ':' + (this.minute < 10 ? '0': '') + this.minute
      }
    }
  },
  emits: ['updateQuestion'],
  data() {
    return {
      value: null,
      hour: null,
      minute: null,
      time: null,
      options: ['AM', 'PM'],//21:05
      timezones: [
        {name: 'America/Los_Angeles'},
        {name: 'America/Denver'},
        {name: 'America/Chicago'},
        {name: 'America/New_York'},
        {name: 'Pacific/Honolulu'},
      ],
    };
  },
};
</script>