<template>
  <full-screen-modal-with-header :init-data="{agree: false}"
                                 v-slot:default="props" v-if="isActive" :active="isActive" :merchant-number="this.application.merchant_number"
                                 title="Merchant Application" prev="banking" current="disclosure">
    <div id="new-merchant-app-2">
      <div class="grid m-0 flex flex-1">
        <div class="p-0 col-12 lg:hidden">
          <div class="step-description step-description-mobile flex flex-column lg:hidden">
            <div class="flex justify-content-between align-items-center">
              <div class="step flex mb-0">
                <div class="step-number step-number-active">5</div>
                <div class="step-name">Add Contacts</div>
              </div>
              <div class="step-header">Step 5 of 5</div>
            </div>
            <p class="gray-text mb-0">Almost done! Please add the business contacts with 25% or more
              ownership.</p>
          </div>
        </div>
        <div class="hidden lg:flex lg:col-3 p-0">
          <div class="step-description hidden lg:flex">
            <div>
              <div class="step-header mb-2">Step 5 of 5</div>
              <p class="gray-text mt-2 mb-5">Almost done! Please add the business contacts with 25% or
                more ownership.</p>
              <FormSteps
                  :completed_forms="this.application.completed_steps.completed_forms"
                  current="businessContacts"
                  @open="this.$emit('open', $event)"
              />
            </div>
          </div>
        </div>
        <div class="lg:col-9 col-12 px-0">
          <div class="step-body mt-2 lg:mt-0">
            <div class="grid mx-0 flex align-items-center mb-3 lg:mb-4">
              <div class="col-12 lg:col-9 px-0 lg:px-2">
                <div class="step-header mb-3">
                  Please add the contacts for the business.
                </div>
                <div class="gray-text mb-2 lg:mb-1">Only add contacts with 25% or more ownership in the
                  business.
                </div>
              </div>
              <div class="col-12 lg:col-3 px-0 lg:px-2 flex justify-content-start lg:justify-content-end">
                <Button class="p-button-outlined p-button-outlined-shaded w-full sm:w-auto p-3 sm:p-2"
                        :disabled="this.loading" @click="openContact()" label="Add Contact"/>
              </div>
            </div>
            <div v-if="loading" class="text-center"><progress-spinner/></div>
            <small v-if="error" class="p-error">{{ error }}</small>
            <div>
              <div v-if="(props.params.disabled = !contacts.length) && !this.loading" class="px-0 lg:px-2 mb-3">
                <div
                    class="step-contacts-empty-list flex flex-column align-items-center justify-content-center ">
                  <img alt="no contacts found" class="step-contacts-empty-icon"
                       src="../../../assets/images/icon-site-survey@2x.png">
                  <p>Whoops! No contacts found.</p>
                </div>
              </div>
              <div v-if="contacts.length" class="px-0 lg:px-2 mb-3">
                <div v-for="contact in contacts" v-bind:key="contact.id"
                    class="step-contacts-list-item flex align-items-center justify-content-between">
                  <p class="cursor-pointer" @click="showContact(contact.id)">{{ contact.name }}</p>
                  <Button class="p-button-success" @click="startSignatures(contact)" label="Collect E-Sig"/>
                </div>
              </div>

              <div v-if="contacts" class="flex mt-5 mb-3 col-12 px-0 lg:px-2">
                <div class="p-field-checkbox mr-4">
                  <Checkbox id="contactsAgree" :disabled="props.params.disabled" :binary="true" v-model="props.data.agree"  class="mr-2"/>
                  <label for="contactsAgree">Yes, I (Merchant) agree that all owners with 25% or more
                    ownership are
                    listed above.</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog
        id="add-contact-modal"
        header="Add Contact"
        v-model:visible="isContactOpen"
        :breakpoints="{'960px': '75vw', '769px': '95vw'}"
        :style="{width: '50vw'}"
        @hide="closeContact()"
    >
      <div v-if="contactLoading" class="text-center"><progress-spinner/></div>
      <small v-if="error" class="p-error">{{ error }}</small>
      <Contact v-if="!contactLoading" :errors="errors" :options="options" :contact-data="contact" @updateContact="editContact($event)" />
      <template #footer>
        <Button label="Cancel" :disabled="contactLoading" @click="closeContact()" class="p-button-outlined p-button-lg"/>
        <Button label="Save" :disabled="contactLoading" @click="saveContact()" class="p-button-lg lg:px-6"/>
      </template>
    </Dialog>
    <signatures
        :contact-id="activeSignaturesContactId"
        :merchant-number="application.merchant_number"
        @close="closeSignatures()"
    />
    <signatures-start
        :contact="activeSignaturesContact"
        @close="this.activeSignaturesContact = null"
        @start="showSignatures($event)"
    />
  </full-screen-modal-with-header>
</template>

<script>
import Contact from './Contact';
import SignaturesStart from './SignaturesStart';
import Signatures from './Signatures';
import FullScreenModalWithHeader from '@/components/FullScreenModalWithHeader';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import FormSteps from './FormSteps';
import ApplicationService from "@/services/api-calls/application.service";
import ProgressSpinner from 'primevue/progressspinner';
import Dialog from 'primevue/dialog';
import FormHelper from "@/services/form.helper";

export default {
  components: {
    FullScreenModalWithHeader,
    Button,
    Checkbox,
    FormSteps,
    ProgressSpinner,
    Contact,
    SignaturesStart,
    Signatures,
    Dialog
  },
  watch: {
    isActive(isActive) {
      if(isActive) {
        this.getContacts();
      }
    },
    application(application, prevApplication) {
      if(this.isActive && application.merchant_number !== prevApplication.merchant_number) {
        this.getContacts();
      }
    }
  },
  props: {
    application: Object,
    activeForm: String,
    isActive: Boolean,
  },
  methods: {
    getDisabled() {
      return this.loading
    },
    openContact() {
      this.isContactOpen = true;
    },
    updateContact(newContact) {
      for (let key in this.contacts) {
        if (this.contacts[key].id === newContact.id) {
          this.contacts[key] = newContact;
        }
      }
    },
    closeContact() {
      this.activeContactId = null;
      this.isContactOpen = false;
      this.error = false;
      this.errors = {};
      this.contact = {address: {}, ownership_amount: 0}
    },
    closeSignatures() {
      this.activeSignaturesContactId = null;
    },
    async saveContact() {
      this.contactLoading = true;

      const result = this.activeContactId
          ? await ApplicationService.updateContact(this.application.merchant_number, this.activeContactId, this.contact)
          : await ApplicationService.createContact(this.application.merchant_number, this.contact)

      this.error = result.error ? FormHelper.convertGatewayError(result) :  null;

      if (result.errors) {
        this.errors = result.errors;
      }

      if (result.id) {
        if (this.activeContactId) {
          this.updateContact(result)
        } else {
          this.contacts.push(result)
        }
        this.closeContact()
      }

      this.contactLoading = false;
    },
    async showContact(id) {
      this.activeContactId = id;
      this.isContactOpen = true;
      this.contactLoading = true;
      const result = await ApplicationService.getData(this.application.merchant_number, 'contacts/' + id);
      if (result) {
        this.contact = result;
        this.contact.citizenship_code += '';
      }
      this.contactLoading = false;
    },
    startSignatures(contact) {
      this.activeSignaturesContact = contact
    },
    showSignatures(id) {
      this.activeSignaturesContact = null;
      this.activeSignaturesContactId = id
    },
    editContact(contact) {
      this.contact = contact
    },
    async getContacts() {
      this.loading = true;
      this.contacts = [];
      this.error = null;
      const result = await ApplicationService.getData(this.application.merchant_number, 'contacts');
      if (result.contacts) {
        this.contacts = result.contacts;
      }
      if (result.options) {
        this.options = FormHelper.convertOptions(result.options);
      }
      if (result.error) {
       this.error = result.error;
      }

      this.loading = false;
    }
  },
  data() {
    return {
      loading: false,
      contactLoading: false,
      error: null,
      errors: {},
      contacts: [],
      contact: {address: {}, ownership_amount: 0},
      options: {},
      activeContactId: null,
      activeSignaturesContactId: null,
      activeSignaturesContact: null,
      isContactOpen: false,
    }
  }
};
</script>