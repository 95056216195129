<template>
  <div class="full-screen-modal-wrapper">
    <div class="modal-backdrop">
      <div class="modal full-screen-modal">
        <Message severity="error" v-if="params.error">{{ params.error }}</Message>
        <header class="modal-header modal-header-with-content">
          <ProgressBar v-if="params.loading" style="position: absolute; left: 0px; top:2px; height: 4px; width: 100%" mode="indeterminate"/>
                <Button
                    type="button"
                    class="p-button-secondary modal-close-btn btn-no-border"
                    @click="close"
                ><i class="pi pi-times"/></Button>
          <h2 class="full-screen-modal-title m-0">{{ title }}</h2>
          <div>
            <Button :disabled="params.loading" v-if="prev" @click="open(this.prev)" class="p-button-outlined p-button-secondary mr-2" label="Previous"/>
            <Button :disabled="params.loading || params.disabled" @click="save()" class="p-button-shaded mr-2" label="Save"/>
            <Button :disabled="params.loading || params.disabled" v-if="next" @click="save(true)" label="Save & Next"/>
            <Button :disabled="params.loading || params.disabled" v-else @click="save(true)" label="Save & Close"/>
          </div>
        </header>
            <section class="modal-body">
                <slot :params="params" :data="data"></slot>
            </section>
        <div class="flex lg:hidden p-fluid p-4">
          <Button :disabled="params.loading" v-if="prev" @click="open(this.prev)"
              class="p-button-outlined p-button-secondary p-button-lg mr-2 mobile-previous-step"><i
              class="pi pi-chevron-left"/></Button>
          <Button :disabled="params.loading || params.disabled" @click="save()" class="p-button-shaded p-button-lg mr-2" label="Save"/>
          <Button :disabled="params.loading || params.disabled" v-if="next" @click="save(true)" class="p-button-lg" label="Save & Next"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import ApplicationService from "@/services/api-calls/application.service";
import FormHelper from "@/services/form.helper";
import ProgressBar from 'primevue/progressbar';
import Message from "primevue/message";

export default {
  name: 'FormModal',
  components: {
    Button,
    ProgressBar,
    Message
  },
  props: {
    merchantNumber: Number,
    title: String,
    current: String,
    next: String,
    prev: String,
    active: Boolean,
    initData: Object
  },
  mounted() {
    this.getData();
  },
  watch: {
    active(isActive) {
      if(isActive) {
        this.getData();
      }
    },
    merchantNumber() {
      if(this.active) {
        this.getData();
      }
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    open(formName) {
      this.$emit('open', formName);
    },
    update(data) {
      this.$emit('update', data);
    },
    checkData(data) {
      data = {...data}
      for (let key in data) {
        if ('object' === typeof data[key] && 'Object' === data[key].constructor.name) {
           data[key] = this.checkData(data[key])
        }
        else if ('string' === typeof data[key] && "" === data[key].trim()) {
           delete data[key]
        }
      }
      return data;
    },
    async save(goNext = false) {
      this.params.loading = true;
      this.params.error = null;
      this.params.errors = {};

      const result = await ApplicationService.submitForm(this.merchantNumber, this.current, this.checkData(this.data));
      if (result.errors) {
        this.params.errors = result.errors
      }
      if (result.message) {
        this.params.error = result.message
      }
      if (result.error) {
        this.params.error = FormHelper.convertGatewayError(result)
      }
      if (result.application) {
        this.$emit('update', result.application);
        if (goNext) {
          this.open(this.next)
        }
      }
      this.params.loading = false;
    },
    async getData() {
      this.params.loading = true;
      this.params.error = null;
      const data = await ApplicationService.getFormData(this.merchantNumber, this.current);

      if (data.data) {
        this.data = {...this.initData, ...data.data};
      }

      if (data.options) {
        this.params.options = FormHelper.convertOptions(data.options);
      }
      if (data.error) {
        this.params.error = data.error;
      }
      this.params.loading = false;
    },
  },
  data() {
    return {
      params: {
        loading: false,
        disabled: false,
        error: null,
        options: {},
        errors: {},
      },
      data: this.initData,
    }
  }
};
</script>