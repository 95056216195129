<template>
  <div class="grid m-0 flex flex-1">
    <small v-if="error" class="p-error">{{error}}</small>
    <div class="col-12 lg:px-2">
      <div class="p-field mb-2 flex flex-column">
        <label for="name" class="mb-2">Product name</label>
        <InputText
            id="name"
            type="text"
            v-model="data.name"
            getFormatter=""
            :disabled="isLoading"
            :class="{'p-invalid': errors.name }"
            aria-describedby="name_help"
        />
        <small id="name_help" v-if="errors.name" class="p-error">{{ errors.name[0] }}</small>
      </div>
      <div class="p-field mb-2 flex flex-column">
        <label class="mb-2">Category</label>
        <Dropdown
            v-model="data.category_id"
            :options="categories"
            optionLabel="name"
            optionValue="id"
            inputId="application-filters"
            placeholder="Select category"
            :class="{'p-invalid': errors.category_id }"
            class="lg:mr-4 mb-3 lg:mb-0 w-full md:w-auto"
        />
        <small id="type_help" v-if="errors.category_id" class="p-error">{{ errors.category_id[0] }}</small>
      </div>
      <div class="flex">
        <div class="p-field lg:col-6 col-12">
          <label for="price" class="mb-2">SKU</label>
          <div class="flex">
            <InputText
                id="sku"
                style="width: 90%"
                v-model="data.sku"
                :disabled="isLoading"
                :class="{'p-invalid': errors.sku }"
                aria-describedby="sku_help"
            />
          </div>
          <small id="sku_help" v-if="errors.sku" class="p-error">{{ errors.sku[0] }}</small>
        </div>
        <div class="p-field lg:col-6 col-12">
        <label for="price" class="mb-2">Price</label>
          <div class="flex ">
            <span class="p-inputgroup-addon"><i class="pi pi-dollar"/></span>
            <InputNumber
                id="price"
                mode="currency"
                currency="USD"
                v-model.number="data.price"
                :disabled="isLoading"
                style="width: 90%"
                :class="{'p-invalid': errors.price }"
                aria-describedby="name_help"
            />
            </div>
          <small id="price_help" v-if="errors.price" class="p-error">{{ errors.price[0] }}</small>
      </div>
      </div>
      <div class="p-field mb-2 flex flex-column">
        <label for="description" class="mb-2">Product Description</label>
        <Textarea
            id="description"
            v-model="data.description"
            getFormatter=""
            :disabled="isLoading"
            :class="{'p-invalid': errors.description }"
            aria-describedby="description_help"
        />
        <small id="description_help" v-if="errors.description" class="p-error">{{ errors.description[0] }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';

export default {
  name: 'ProductForm',
  components: {
    InputText,
    Textarea,
    Dropdown,
    InputNumber
  },
  updated() {
    this.data = this.product
  },
  props: {
    isLoading: Boolean,
    errors: Object,
    error: String,
    product: Object,
    categories: Object,
  },
  emits: ['update:product'],
  watch: {
    data: {
      handler() {
        this.$emit('update:product', this.data)
      },
      deep: true
    },
  },
  data() {
    return {
      data: this.product
    };
  },
};
</script>