import ApiService from '../api.service';

class Products {
    async getAll(itemsPerPage = 10, currentPage = 1, filter = []) {
        return await ApiService.request(
            'get',
            'admin/product/',
            {"per_page": itemsPerPage, "page": currentPage, "filter[name]": filter.name,  "filter[value]": filter.value});

    }

    async getByCategory(categoryId) {
        return await ApiService.request('get', 'product_category/' + categoryId + '/products', {} )
    }
    async getAllCategories() {
        return await ApiService.request('get', 'product_category', {} )
    }

    async createProduct(data) {
        data.price = data.price * 100
        return await ApiService.request('post', 'admin/product/', data)
    }

    async updateProduct(productId, data) {
        data.price = data.price * 100
        return await ApiService.request('patch', 'admin/product/' + productId, data)
    }

    async createCategory(data) {
        return await ApiService.request('post', 'admin/product_category/', data)
    }

    async updateCategory(categoryId, data) {
        return await ApiService.request('patch', 'admin/product_category/' + categoryId, data)
    }
}


export default new Products();