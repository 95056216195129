<template>
    <page title="Applications">
      <div class="mb-4">
        <applications-list-toolbar :filter="filter" @created="newApplication($event)" @changeFilter="changeFilter($event)"/>
      </div>
      <applications-list-table v-bind:filter="filter" v-bind:updateTrigger="updateTrigger" />
    </page>
</template>

<script>
import Page from '@/components/Page'
import ApplicationsListToolbar from '@/components/Applications/ApplicationsListToolbar';
import ApplicationsListTable from '@/components/Applications/ApplicationsListTable';

export default {
  components: {
    ApplicationsListToolbar,
    ApplicationsListTable,
    Page
  },
  methods: {
    changeFilter($event) {
      this.filter = $event;
    },
    newApplication() {
      this.updateTrigger = !this.updateTrigger
    }
  },
  data() {
    return {
      filter: {},
      updateTrigger: false,
    }
  }
}
</script>
