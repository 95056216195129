import axios from 'axios';

class AuthService {
    login(user, isCustomer = false) {
        return axios.create()
            .post(process.env.VUE_APP_BACKEND_URL + 'auth', {
                ...user,
                client_id: isCustomer ? process.env.VUE_APP_BACKEND_CUSTOMER_CLIENT_ID : process.env.VUE_APP_BACKEND_CLIENT_ID,
                client_secret: isCustomer ? process.env.VUE_APP_BACKEND_CUSTOMER_CLIENT_SECRET : process.env.VUE_APP_BACKEND_CLIENT_SECRET,
            })
            .then(response => {
                this.auth(response.data);

                return response.data;
            });
    }

    register(user) {
        return axios.create()
            .post(process.env.VUE_APP_BACKEND_URL + 'register', {
                ...user,
                client_id: process.env.VUE_APP_BACKEND_CLIENT_ID,
                client_secret: process.env.VUE_APP_BACKEND_CLIENT_SECRET,
                grant_type: 'password'
            })
            .then(response => {
                this.auth(response.data);

                return response.data;
            });
    }

    start(token) {
        return axios.create()
            .post(process.env.VUE_APP_BACKEND_URL + 'register/start', {
                token:token
            })
            .then(response => {
                this.auth(response.data);

                return response.data;
            });
    }

    auth(data) {
        if (data.access_token && data.refresh_token && data.expires_in) {
            const now = Math.round(Date.now() / 1000);
            localStorage.setItem(
                'user',
                JSON.stringify({...data, expires_at: now + data.expires_in})
            );
        }
        if (data.role && 'client' === data.role) {
            localStorage.setItem('isCustomer', '1');
        } else {
            localStorage.removeItem('isCustomer');
        }
    }

    forgot(email) {
        return axios.create()
            .post(process.env.VUE_APP_BACKEND_URL + 'password/forgot', {
                email: email,
                target: process.env.VUE_APP_URL + 'reset-password/'
            })
            .then(response => {
                return response.data;
            }).catch(error => {
                return error.response.data;
            })
    }

    reset(data) {
        return axios.create()
            .patch(process.env.VUE_APP_BACKEND_URL + 'password/reset', data)
            .then(response => {
                return response.data;
            }).catch(error => {
                return error.response;
            })
    }

    logout() {
        localStorage.removeItem('user');
        window.location = localStorage.getItem('isCustomer') ? 'login/customer' : 'login';
    }
}

export default new AuthService();