<template>
  <Orders/>
</template>

<script>
import Orders from '../components/Orders/Orders'

export default {
  components: {
    Orders
  }
}
</script>
