<template>
  <Users/>
</template>

<script>
import Users from '../components/Users.vue'

export default {
  components: {
    Users
  }
}
</script>
