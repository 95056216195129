<template>
  <full-screen-modal @close="close()">
    <div id="new-merchant-app-intro">
      <div id="new-merchant-app-intro-content">
        <img
          id="new-merchant-app-logo"
          alt="new application logo"
          src="../../assets/images/new-application-form-graphic@2x.png"
        />
        <h1 class="heading mb-4 lg:mb-0">
          Follow the steps below to start a new merchant application!
        </h1>
        <p class="gray-text mb-6 hidden lg:block">
          Fill out some initial details to speed up the application process.
        </p>

        <div v-if="accounts.length" class="grid flex align-items-start mb-3">
          <div class="col-12 lg:col-4">
            <div class="subheading">Select Parse Pay Account</div>
            <p class="gray-text">
              We first need to link this application to a BackOffice account. If
              you cannot locate the account, create a new one.
            </p>
          </div>

          <div class="col-12 lg:col-4 flex flex-column">
            <label class="mb-2" for="select-account-dropdown"
              >Select Account</label
            >
            <Dropdown
              v-model="selectedAccount"
              :options="accounts"
              optionLabel="name"
              inputId="select-account"
              id="select-account-dropdown"
              placeholder="Select"
              @change="selectAccount()"
            ></Dropdown>
          </div>

          <div class="col-12 lg:col-2 relative mt-0 lg:mt-4 p-fluid">
            <Button
              :disabled="!selectedAccount || loading"
              id="start-application-btn"
              class="p-button-lg p-button-success hidden lg:block"
              @click="createApplication"
              label="Start Application"
            ></Button>
          </div>
        </div>

        <div v-if="loading" class="text-center">
          <progress-spinner></progress-spinner>
        </div>

        <Message severity="error" v-if="error">
          {{ error }}
        </Message>

        <Message severity="success" v-if="success">
          Merchant account {{ application.merchant_number }} created
        </Message>

        <div v-if="application" class="hidden lg:block">
          <application v-bind:merchant-number="application.merchant_number" />
        </div>
      </div>

      <div
        v-if="accounts.length"
        class="new-merchant-app-mobile-footer d-flex lg:hidden p-3 p-fluid"
      >
        <Button
          :disabled="!selectedAccount || loading"
          @click="createApplication"
          label="Start Application"
          class="flex-1 p-button-lg p-button-success"
        ></Button>
      </div>
    </div>

    <create-account
      @created="setNewApplication($event)"
      @close="closeCreateUserOpenModal"
      :is-open="isCreateUserOpenModal"
    ></create-account>
  </full-screen-modal>
</template>

<script>
import FullScreenModal from '@/components/FullScreenModal';
import CreateAccount from './CreateAccountModal';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Application from '@/components/Application/Main';
import UsersService from '@/services/api-calls/users.service';
import ProgressSpinner from 'primevue/progressspinner';
import ApplicationService from '@/services/api-calls/application.service';
import Message from 'primevue/message';

export default {
  components: {
    Application,
    FullScreenModal,
    Button,
    Dropdown,
    ProgressSpinner,
    Message,
    CreateAccount,
  },

  data() {
    return {
      application: null,
      selectedAccount: null,
      success: false,
      loading: false,
      isCreateUserOpenModal: false,
      error: null,
      accounts: [{ name: 'Create new', value: 'create-new' }],
    };
  },

  methods: {
    close() {
      this.application = null;
      this.success = false;
      this.isCreateUserOpenModal = false;
      this.selectedAccount = null;
      this.error = null;
    },

    selectAccount() {
      if ('create-new' === this.selectedAccount.value) {
        this.selectedAccount = null;
        this.isCreateUserOpenModal = true;
      }
    },

    closeCreateUserOpenModal() {
      this.isCreateUserOpenModal = false;
    },

    async createApplication() {
      this.success = false;
      this.error = null;
      this.loading = true;
      this.application = null;
      const result = await ApplicationService.create({
        parse_pos_user_id: this.selectedAccount.value,
      });
      if (result.success) {
        this.accounts = this.accounts.filter((account) => {
          return account.value !== this.selectedAccount.value;
        });
        this.setNewApplication(result.application);
        this.selectedAccount = null;
      } else {
        this.error = result.error;
      }
      this.loading = false;
    },

    setNewApplication(application) {
      this.success = true;
      this.isCreateUserOpenModal = false;
      this.application = application;
      this.$emit('created', application);
    },

    async getParsePosUsers() {
      this.loading = true;
      const result = await UsersService.getParsePosUsers();
      if (result.success) {
        for (let key in result.users) {
          this.accounts.push({
            name: result.users[key].name,
            value: result.users[key].id,
          });
        }
      } else {
        this.error = result.error;
      }
      this.loading = false;
    },
  },

  created() {
    this.getParsePosUsers();
  },
};
</script>
