<template>
  <div
    id="sidebar"
    class="hidden lg:flex"
    :class="{ 'sidebar-collapsed': !sidebarIsOpen }"
  >
    <ul id="nav-list" class="ml-0 mr-0 mt-3 p-0 w-full">
      <router-link
        :to="item.to"
        v-for="item in navItems"
        :key="item.label"
        class="no-underline"
      >
        <div v-if="item.visible" class="nav-item">
          <div class="nav-item-sidebar"></div>
          <div class="nav-item-content">
            <div class="nav-item-icon">
              <i :class="item.icon"></i>
            </div>
            {{ item.label }}
          </div>
        </div>
      </router-link>
    </ul>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {
    currentUserRole() {
      return (
        (this.$store.state.auth.user && this.$store.state.auth.user.role) ||
        null
      );
    },
  },
  created() {
    this.navItems = [
      {
        label: 'Applications',
        icon: 'pi pi-fw pi-check-square',
        to: '/',
        visible: true,
      },
      {
        label: 'Invite',
        icon: 'pi pi-fw pi-user',
        to: '/invite',
        visible: 'admin' === this.currentUserRole,
      },
      {
        label: 'Users',
        icon: 'pi pi-fw pi-users',
        to: '/users',
        visible: 'admin' === this.currentUserRole,
      },
      {
        label: 'Products',
        icon: 'pi pi-fw pi-tag',
        to: '/products',
        visible: 'admin' === this.currentUserRole,
      },
      {
        label: 'Forms',
        icon: 'pi pi-fw pi-tag',
        to: '/forms',
        visible: true,
      },
      {
        label: 'Merchants',
        icon: 'pi pi-fw pi-tag',
        to: '/merchants',
        visible: true,
      },
      {
        label: 'Documents',
        icon: 'pi pi-fw pi-tag',
        to: '/documents',
        visible: true,
      },
    ];
  },
  watch: {
    $route: 'currentRoute',
  },
  props: ['sidebarIsOpen'],
  data() {
    return {
      navItems: [],
      routeName: null,
    };
  },
  methods: {
    currentRoute() {
      this.$nextTick(() => {
        this.routeName = this.$route.name;
      });
    },
  },
};
</script>
