<template>
    <div id="applications-list-toolbar">
        <div
            class="flex flex-column-reverse lg:flex-row justify-content-between align-items-start md:align-items-center">
            <div class="flex flex-column lg:flex-row flex-1 w-full">
                <Dropdown
                    v-model="activeFilter"
                    :options="filters"
                    @change="changeFilter"
                    optionLabel="name"
                    inputId="application-filters"
                    id="application-filters-dropdown"
                    placeholder="Filter Results"
                    class="lg:mr-4 mb-3 lg:mb-0 w-full md:w-auto"
                />
                <div id="applications-searchbar-container" class="p-fluid lg:mr-4">
          <span class="p-input-icon-left">
            <i class="pi pi-search"/>
            <InputText id="applications-searchbar" class="p-fluid" type="text" @change="changeFilter"
                       v-model="activeFilterValue"/>
          </span>
                </div>
            </div>
            <div class="flex sm:w-full md:w-auto mb-3 lg:mb-0 w-full md:w-auto">
                <Button
                    class="p-button-lg p-button-success w-full md:w-auto flex justify-content-center"
                    @click="showModal"
                >
                    New Application
                </Button>
                <merchant-app-modal
                    v-show="isMerchantApplicationModalOpen"
                    @close="closeModal"
                    @created="created($event)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import MerchantAppModal from '@/components/NewMerchantApplication/Intro';

export default {
    name: 'ApplicationsListToolbar',
    components: {
        InputText,
        Button,
        Dropdown,
        MerchantAppModal,
    },
    methods: {
        showModal() {
            this.isMerchantApplicationModalOpen = true;
        },
        closeModal() {
            this.isMerchantApplicationModalOpen = false;
        },
        changeFilter() {
            this.$emit('changeFilter', {'name': this.activeFilter.value, 'value': this.activeFilterValue});
        },
        created(newApplication) {
          this.$emit('created', newApplication);
        },
    },
    data() {
        return {
            options: ['AM', 'PM'],
            displayBasic: false,
            isMerchantApplicationModalOpen: false,
            activeFilter: null,
            activeFilterValue: null,
            activeState: 'type2',
            filters: [
                {name: 'Business Name', value: 'name_of_business'},
                {name: 'Primary Contact', value: 'contact'},
                {name: 'Merchant number', value: 'merchant_number'},
            ],
            states: [
                {name: 'Type 1', value: 'type1'},
                {name: 'Type 2', value: 'type2'},
            ],
        };
    },
};
</script>