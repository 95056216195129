<template>
    <full-screen-modal-with-header
        :init-data="{phones:{}, docs_online: false, same_mailing: false, tax_online: false }"
        v-slot:default="props" v-if="this.isActive" :active="this.isActive" :merchant-number="this.application.merchant_number"
        title="Merchant Application" current="general" next="business">
      <div id="new-merchant-app-1">
          <div class="grid m-0 flex flex-1">
            <div class="p-0 col-12 lg:hidden">
                    <div class="step-description step-description-mobile flex flex-column lg:hidden">
                        <div class="flex justify-content-between">
                            <div class="step flex">
                                <div class="step-number step-number-active">1</div>
                                <div class="step-name">General Information</div>
                            </div>
                            <div class="step-header mb-2">Step 1 of 5</div>
                        </div>
                        <p class="gray-text mb-0">Enter the general business information and preferences for
                            receiving statements and forms.</p>
                    </div>
                </div>
                    <div class="hidden lg:flex lg:col-3 p-0">
                    <div class="step-description hidden lg:flex">
                        <div>
                            <div class="step-header mb-2">Step 1 of 5</div>
                            <p class="gray-text mt-2 mb-5">Enter the general business information and preferences for
                                receiving statements and forms.</p>
                          <FormSteps
                              :completed_forms="this.application.completed_steps.completed_forms"
                              current="general"
                              @open="this.$emit('open', $event)"
                          />
                        </div>
                    </div>
                </div>
                <div class="lg:col-9 col-12 p-0">
                    <div class="step-body mt-2 lg:mt-0">
                        <div class="step-header mb-5 px-0 lg:px-2">
                            Let’s start with the general information of your business.
                        </div>
                        <div class="grid mx-0 mb-3">
                            <div class="col-12 lg:col-6 px-0 lg:px-2">
                                <div class="p-field mb-2 flex flex-column">
                                    <label for="business_name" class="mb-2">Business Name (DBA Name)</label>
                                    <InputText
                                        v-model="props.data.business_name"
                                        id="business_name"
                                        placeholder="Business Name"
                                        type="text"
                                        :disabled="props.params.loading"
                                        :class="{'p-invalid': props.params.errors.business_name }"
                                        aria-describedby="business_name_help"
                                    />
                                </div>
                              <small id="business_name_help" v-if="props.params.errors.business_name" class="p-error">{{ props.params.errors.business_name[0] }}</small>
                            </div>
                            <div class="col-12 lg:col-6 px-0 lg:px-2">
                                <div class="p-field mb-2 flex flex-column">
                                    <label for="select-mcc" class="mb-2">Select MCC</label>
                                    <Dropdown
                                        v-model="props.data.mcc"
                                        :options="props.params.options.mmcTypes ?? []"
                                        optionLabel="name"
                                        optionValue="value"
                                        id="select-mcc"
                                        placeholder="Filter Results"
                                        :disabled="props.params.loading"
                                        :class="{'p-invalid': props.params.errors.mcc }"
                                        aria-describedby="mcc_help"
                                    />
                                  <small id="mcc_help" v-if="props.params.errors.mcc" class="p-error">{{ props.params.errors.mcc[0] }}</small>
                                </div>
                            </div>
                            <div class="col-12 lg:col-6 px-0 lg:px-2">
                                <div class="p-field mb-2 flex flex-column">
                                    <label for="street" class="mb-2">Street Address</label>
                                    <InputText
                                        id="street"
                                        type="text"
                                        v-model="props.data.street"
                                        :disabled="props.params.loading"
                                        :class="{'p-invalid': props.params.errors.street }"
                                        aria-describedby="street_help"
                                    />
                                  <small id="street_help" v-if="props.params.errors.street" class="p-error">{{ props.params.errors.street[0] }}</small>
                                </div>
                            </div>
                            <div class="col-12 lg:col-6">
                                <div class="grid">
                                  <div class="col-12 lg:col-6 px-0 lg:px-2">
                                        <div class="p-field mb-2 flex flex-column">
                                            <label for="city" class="mb-2">City</label>
                                            <InputText
                                                id="city"
                                                type="text"
                                                v-model="props.data.city"
                                                :disabled="props.params.loading"
                                                :class="{'p-invalid': props.params.errors.city }"
                                                aria-describedby="city_help"
                                            />
                                          <small id="city_help" v-if="props.params.errors.city" class="p-error">{{ props.params.errors.city[0] }}</small>
                                        </div>
                                    </div>
                                    <div class="col-12 lg:col-3 px-0 lg:px-2">
                                        <div class="p-field mb-2 flex flex-column">
                                            <label for="state" class="mb-2">State</label>
                                            <Dropdown
                                                v-model="props.data.state"
                                                :options="props.params.options.states ?? []"
                                                optionLabel="name"
                                                optionValue="value"
                                                id="state"
                                                placeholder="Select"
                                                :disabled="props.params.loading"
                                                :class="{'p-invalid': props.params.errors.state }"
                                                aria-describedby="state_help"
                                            />
                                        </div>
                                      <small id="state_help" v-if="props.params.errors.state" class="p-error">{{ props.params.errors.state[0] }}</small>
                                    </div>
                                    <div class="col-12 lg:col-3 px-0 lg:px-2">
                                        <div class="p-field mb-2 flex flex-column">
                                            <label for="zip" class="mb-2">Zip</label>
                                            <InputText
                                                id="zip"
                                                type="text"
                                                v-model="props.data.zip"
                                                :disabled="props.params.loading"
                                                :class="{'p-invalid': props.params.errors.zip }"
                                                aria-describedby="zip_help"
                                            />
                                          <small id="zip_help" v-if="props.params.errors.zip" class="p-error">{{ props.params.errors.zip[0] }}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 lg:col-6 px-0 lg:px-2">
                                <div class="p-field mb-2 flex flex-column">
                                    <label for="phone" class="mb-2">Business Phone Number</label>
                                    <InputMask
                                        id="phone"
                                        placeholder="(___) ___-____"
                                        mask="(999) 999-9999"
                                        type="text"
                                        v-model="props.data.phones.phone"
                                        :disabled="props.params.loading"
                                        v-on:change="props.data = phones('phone', props.data)"
                                        :class="{'p-invalid': props.params.errors['phones.phone'] }"
                                        aria-describedby="phone_help"
                                    />
                                  <small id="phone_help" v-if="props.params.errors['phones.phone']" class="p-error">{{ props.params.errors['phones.phone'][0] }}</small>
                                </div>
                            </div>
                            <div class="col-12 lg:col-6 px-0 lg:px-2">
                                <div class="p-field mb-2 flex flex-column">
                                    <label for="fax" class="mb-2">Fax Number (optional)</label>
                                    <InputMask
                                        id="fax"
                                        placeholder="(___) ___-____"
                                        mask="(999) 999-9999"
                                        v-model="props.data.phones.fax"
                                        :disabled="props.params.loading"
                                        @change="props.data = phones('fax', props.data)"
                                        :class="{'p-invalid': props.params.errors['phones.fax'] }"
                                        aria-describedby="fax_help"
                                    />
                                  <small id="fax_help" v-if="props.params.errors['phones.fax']" class="p-error">{{ props.params.errors['phones.fax'][0] }}</small>
                                </div>
                            </div>
                            <div class="col-12 lg:col-6 px-0 lg:px-2">
                                <div class="p-field mb-2 flex flex-column">
                                    <label for="businessEmail" class="mb-2">Business Email Address</label>
                                    <InputText
                                        id="businessEmail"
                                        placeholder="Example: john@gmail.com"
                                        type="text"
                                        v-model="props.data.email"
                                        :disabled="props.params.loading"
                                        :class="{'p-invalid': props.params.errors.email }"
                                        aria-describedby="email_help"
                                    />
                                  <small id="email_help" v-if="props.params.errors.email" class="p-error">{{ props.params.errors.email[0] }}</small>
                                </div>
                            </div>
                            <div class="col-12 lg:col-6 px-0 lg:px-2">
                                <div class="p-field mb-2 flex flex-column">
                                    <label for="businessWeb" class="mb-2">Business Web Address</label>
                                    <InputText
                                        id="businessWeb"
                                        placeholder="www.example.com"
                                        type="text"
                                        v-model="props.data.url"
                                        :disabled="props.params.loading"
                                        :class="{'p-invalid': props.params.errors.url }"
                                        aria-describedby="url_help"
                                    />
                                  <small id="url_help" v-if="props.params.errors.url" class="p-error">{{ props.params.errors.url[0] }}</small>
                                </div>
                            </div>
                          <div v-if="!props.data.same_mailing" class="col-12 lg:col-6">
                            <div class="p-field mb-2 flex flex-column">
                              <label for="street" class="mb-2">Mail Street Address</label>
                              <InputText
                                  id="street_mailing"
                                  type="text"
                                  v-model="props.data.street_mailing"
                                  :disabled="props.params.loading"
                                  :class="{'p-invalid': props.params.errors.street_mailing }"
                                  aria-describedby="street_mailing_help"
                              />
                              <small id="street_mailing_help" v-if="props.params.errors.street_mailing" class="p-error">{{ props.params.errors.street_mailing[0] }}</small>
                            </div>
                          </div>
                          <div v-if="!props.data.same_mailing" class="col-12 lg:col-6">
                            <div class="grid">
                              <div class="col-12 lg:col-6">
                                <div class="p-field mb-2 flex flex-column">
                                  <label for="city" class="mb-2">City</label>
                                  <InputText
                                      id="city_mailing"
                                      type="text"
                                      v-model="props.data.city_mailing"
                                      :disabled="props.params.loading"
                                      :class="{'p-invalid': props.params.errors.city_mailing }"
                                      aria-describedby="city_mailing_mailing_help"
                                  />
                                  <small id="city_mailing_mailing_help" v-if="props.params.errors.city_mailing" class="p-error">{{ props.params.errors.city_mailing[0] }}</small>
                                </div>
                              </div>
                              <div class="col-12 lg:col-3">
                                <div class="p-field mb-2 flex flex-column">
                                  <label for="state" class="mb-2">State</label>
                                  <Dropdown
                                      v-model="props.data.state_mailing"
                                      :options="props.params.options.states ?? []"
                                      optionLabel="name"
                                      optionValue="value"
                                      id="state_mailing"
                                      placeholder="Select"
                                      :disabled="props.params.loading"
                                      :class="{'p-invalid': props.params.errors.state_mailing }"
                                      aria-describedby="state_mailing_help"
                                  />
                                  <small id="state_mailing_help" v-if="props.params.errors.state_mailing" class="p-error">{{ props.params.errors.state_mailing[0] }}</small>
                                </div>
                              </div>
                              <div class="col-12 lg:col-3">
                                <div class="p-field mb-2 flex flex-column">
                                  <label for="zip" class="mb-2">Zip</label>
                                  <InputText
                                      id="zip_mailing"
                                      type="text"
                                      v-model="props.data.zip_mailing"
                                      :disabled="props.params.loading"
                                      :class="{'p-invalid': props.params.errors.zip_mailing }"
                                      aria-describedby="zip_mailing_help"
                                  />
                                  <small id="zip_mailing_help" v-if="props.params.errors.zip_mailing" class="p-error">{{ props.params.errors.zip_mailing[0] }}</small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> <!--end grid --->
                        <div class="flex mb-5 px-0 lg:px-2">
                            <Checkbox :binary="true" :disabled="props.params.loading" v-model="props.data.same_mailing" class="mr-2"/>
                          <div>My mailing address is the same as above</div>
                        </div>
                        <div class="px-0 lg:px-2">
                            <div class="step-header mb-4">Go Paperless</div>
                            <div class="flex mb-3">
                                <Checkbox :binary="true" :disabled="props.params.loading" v-model="props.data.docs_online" class="mr-2"/>
                                <div>Statement & Documents (online only)</div>
                            </div>
                            <div class="flex">
                                <Checkbox :binary="true" name="tax_online" :disabled="props.params.loading" v-model="props.data.tax_online" value="true" class="mr-2"/>
                                <div>Tax Statements (online only)</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </full-screen-modal-with-header>
</template>

<script>
import FullScreenModalWithHeader from '@/components/FullScreenModalWithHeader';
import FormSteps from './FormSteps';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import InputMask from 'primevue/inputmask';

export default {
    components: {
        FullScreenModalWithHeader,
        InputText,
        InputMask,
        Dropdown,
        Checkbox,
        FormSteps
    },
    props: {
      application: Object,
      isActive: Boolean,
    },
  methods: {
    phones(type, data) {
      if ('' === data.phones[type]) {
        delete data.phones[type];
      }
      return data;
    }
  }
};
</script>