<template>
  <div id="app">
    <Header @toggleSidebar="toggleSidebar"/>
    <div id="app-body">
      <Sidebar :sidebar-is-open="sidebarIsOpen"/>
      <div id="app-content">
        <router-view></router-view>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header';
import Sidebar from './Sidebar';

export default {
  components: {
    Sidebar,
    Header,
  },
  methods: {
    toggleSidebar() {
      this.sidebarIsOpen = !this.sidebarIsOpen;
    },
  },
  data() {
    return {
      sidebarIsOpen: true,
    };
  },
};
</script>
