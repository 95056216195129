import ApiService from '../api.service';

class Orders {
    async getAll(itemsPerPage = 10, currentPage = 1, filter = []) {
        return await ApiService.request(
            'get',
            'order/',
            {"per_page": itemsPerPage, "page": currentPage, "filter[name]": filter.name,  "filter[value]": filter.value});

    }

    async get(orderId) {
        return await ApiService.request(
            'get', 'order/' + orderId, {});
    }

    async getQuote(orderId) {
        return await ApiService.request(
            'get', 'order/' + orderId + '/quote', {
                target: process.env.VUE_APP_URL
            });
    }

    async sendQuote(orderId) {
        return await ApiService.request(
            'post', 'order/' + orderId + '/quote/send', {
                target: process.env.VUE_APP_URL
            });
    }

    async downloadQuote(orderId) {
        return await ApiService.downloadRequest(
            'get', 'order/' + orderId + '/quote', {
                target: process.env.VUE_APP_URL,
                pdf: 1,
            },
            'application/pdf',
            'quote_' + orderId
        );
    }

    async complete(orderId) {
        return await ApiService.request(
            'patch', 'admin/order/' + orderId + '/complete', {});
    }

    async create(order) {
        return await ApiService.request('post', 'order/', order);
    }

    async update(orderId, order) {
        return await ApiService.request('patch', 'order/' + orderId, order);
    }
}

export default new Orders();