import ApiService from '../api.service';

class Application {
     getAll(itemsPerPage = 10, currentPage = 1, filter = []) {
        return ApiService.request(
            'get',
            'application',
            {"per_page": itemsPerPage, "page": currentPage, "filter[name]": filter.name,  "filter[value]": filter.value}
            ).then(
            (result) => {
                if (result && result.items) {
                    return {success: true, applications: result.items, itemsPerPage: result.perPage, total: result.total, first: result.from, last: result.to, currentPage: result.currentPage}
                }
                if (result && result.error) {
                    return { success: false, error: result.error }
                }

                return { success: false, error: "invalid response" }
            },
        );
    }

    get(merchantNumber) {
        return ApiService.request('get', 'application/' + merchantNumber ).then(
            (result) => {
                if (result) {
                    return result;
                }
                return { success: false, error: "invalid response" }
            },
        );
    }

    getFirst() {
        return ApiService.request('get', 'application').then(
            (result) => {
                if (result && result.items) {
                    return {success: true, application: result.items[0]}
                }
                if (result && result.message) {
                    return { success: false, error: result.message }
                }

                return { success: false, error: "invalid response" }
            },
        );
    }

    create(data) {
        return ApiService.request('post', 'application', data).then(
            (result) => {
                if (result && result.merchant_number) {
                    return {success: true, application: result}
                }
                let data = {success: false}
                if (result.error || result.message) {
                    data.error = result.error ?? result.message;
                }
                if (result.errors) {
                    data.errors = result.errors;
                }

                return data;
            }
        );
    }

    getData(merchantNumber, formName) {
        return ApiService.request('get', 'application/' + merchantNumber + '/' + formName).then(
            (result) => {
                return result;
            }
        );
    }

    getFormData(merchantNumber, formName) {
        return ApiService.request('get', 'application/' + merchantNumber + '/' + formName).then(
            (result) => {
                if (result) {
                    let data = {};
                    if (result.form) {
                        data.data = result.form
                    }
                    if (result.options) {
                        data.options = result.options;
                    }
                    if (result.error) {
                        data.error = result.error;
                    }
                    return data;
                }
                return {error: "invalid response"}
            },
        );
    }

    submitForm(merchantNumber, formName, data) {
         if ('banking' === formName) {
             return this.submitFormBankingForm(merchantNumber, data);
         }
        return ApiService.request('put', 'application/' + merchantNumber + '/' + formName, data).then(
            (result) => {
                if (result) {
                    return result;
                }
                return {error: "invalid response" }
            },
        );
    }

    async submitFormBankingForm(merchantNumber, data) {
         let check = null;
         if (data.check) {
             check = data.check;
             delete data.check;
         }
        const result = await ApiService.request('put', 'application/' + merchantNumber + '/banking', data);
        if (result.bank_account_id && check) {
            const uploadResult =  await this.uploadVoidedCheck(merchantNumber, result.bank_account_id, check);
            return {...result, ...uploadResult};
        } else {
            return result;
        }
    }

     uploadVoidedCheck(merchantNumber, bankAccountId, check) {
        let formData = new FormData();
        formData.append('bank_account_id', bankAccountId);
        formData.append('check', check);

        return ApiService.uploadRequest('application/' + merchantNumber + '/checks', formData).then(
            (result) => {
                return result;
            }
        )
    }

    createContact(merchantNumber, data) {
        return ApiService.request('post', 'application/' + merchantNumber + '/contacts', data).then(
            (result) => {
                if (result) {
                    return result;
                }
                return {error: "invalid response" }
            },
        );
    }

    updateContact(merchantNumber, contactId, data) {
        return ApiService.request('patch', 'application/' + merchantNumber + '/contacts/' + contactId, data).then(
            (result) => {
                if (result) {
                    return result;
                }
                return {error: "invalid response" }
            },
        );
    }

    getSignatures(merchantNumber, contactId) {
        return ApiService.request('get', 'application/' + merchantNumber + '/contacts/' + contactId + '/signatures');
    }

    submitSignatures(merchantNumber, contactId, signed) {
        return ApiService.request(
            'put',
            'application/' + merchantNumber + '/contacts/' + contactId + '/signatures',
            {signature_type_ids: signed}
        );
    }
}

export default new Application();