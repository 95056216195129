<template>
  <router-view />
</template>

<style>
body{
  background-color: #F5F7F8;
  font-family: 'Roboto', sans-serif;
  margin: 0;
}

a {
  color: #057CFD;
}

</style>
