<template>
  <Dialog
      id="merchant-verification-modal"
      header="Merchant Verification"
      v-model:visible="isOpen"
      @hide="hide()"
      :breakpoints="{'960px': '75vw', '769px': '95vw'}"
      :style="{width: '50vw'}"
      :closable="!isLoading"
  >
    <template #header>
      <span class="p-dialog-title">Merchant Verification</span>
      <div class="flex">
        <ProgressBar v-if="isLoading" style="position: absolute; top:2px; left: 0px; height: 4px; width: 100%" mode="indeterminate"/>
      </div>
    </template>
    <div class="grid m-0 flex flex-1">
      <div class="col-12 px-0 lg:px-2">
        <div class="step-header mb-2 lg:mb-3 line-height-3">Please answer the following questions
          to verify the merchant
        </div>
      </div>
      <div class="col-12 px-0 lg:px-2">
        <div class="flex flex-column mb-2">
          <p class="mt-0 mb-4 lg:mb-2">How would you best describe the location of the
            business?</p>
          <div class="flex flex-column lg:flex-row">
            <div class="p-field-radiobutton mr-4 mb-4 lg:mb-0 flex align-items-center">
              <RadioButton id="location2" name="city" :value="2" v-model="data.location"/>
              <label for="location2">Brick & Mortar</label>
            </div>
            <div class="p-field-radiobutton mr-4 mb-4 lg:mb-0 flex align-items-center">
              <RadioButton id="location3" name="city" :value="3" v-model="data.location"/>
              <label for="location3">Tradeshow</label>
            </div>
            <div class="p-field-radiobutton mr-4 mb-3 lg:mb-0 flex align-items-center">
              <RadioButton id="location4" name="city" :value="4" v-model="data.location"/>
              <label for="location4">Resident</label>
            </div>
            <div class="p-field-radiobutton mr-4 mb-2 lg:mb-0 flex align-items-center">
              <RadioButton id="location1" name="city" :value="1" v-model="data.location"/>
              <div class="flex align-items-center">
                <label class="mr-3" for="location1">Other</label>
                <InputText
                    id="other"
                    type="text"
                    v-model="data.location_name"
                    :class="{'p-invalid': errors.location_name }"
                    aria-describedby="location_name_help"
                />
              </div>
            </div>
          </div>
          <small id="location_help" v-if="errors.location" class="p-error">{{ errors.location[0] }}</small>
          <small id="location_name_help" v-if="errors.location_name" class="p-error">{{ errors.location_name[0] }}</small>
        </div>
      </div>
      <div class="col-12 px-0 lg:px-2">
        <div class="flex flex-column mb-3 ">
          <p class="mt-0">Have you conducted an in-person site survey?</p>
          <div class="flex">
            <div class="p-field-radiobutton mr-4">
              <RadioButton id="conducted1" name="city" @change="changeData()" :value="true" v-model="data.is_conducted_in_person"/>
              <label for="conducted1">Yes</label>
            </div>
            <div class="p-field-radiobutton mr-4">
              <RadioButton id="conducted2" name="city" @change="changeData()" :value="false" v-model="data.is_conducted_in_person"/>
              <label for="conducted2">No</label>
            </div>
          </div>
        </div>
        <small v-if="errors.is_conducted_in_person" class="p-error">{{ errors.is_conducted_in_person[0] }}</small>
      </div>
      <div v-if="!data.is_conducted_in_person" class="col-12 px-0 lg:px-2">
        <div class="flex flex-column mb-2">
          <p class="mt-0">Merchant Acquisition Type</p>
          <div class="flex flex-column lg:flex-row">
            <div class="p-field-radiobutton mr-4 mb-4 lg:mb-0 flex align-items-center">
              <RadioButton id="acquisition1" name="city" :value="1" v-model="data.acquisition_type_id"/>
              <label for="acquisition1">Rep called merchant</label>
            </div>
            <div class="p-field-radiobutton mr-4 mb-4 lg:mb-0 flex align-items-center">
              <RadioButton id="acquisition2" name="city" :value="2" v-model="data.acquisition_type_id"/>
              <label for="acquisition2">Merchant called rep</label>
            </div>
            <div class="p-field-radiobutton mr-4 mb-3 lg:mb-0 flex align-items-center">
              <RadioButton id="acquisition3" name="city" :value="3" v-model="data.acquisition_type_id"/>
              <label for="acquisition3">Web lead</label>
            </div>
          </div>
        </div>
        <small v-if="errors.acquisition_type_id" class="p-error">{{ errors.acquisition_type_id[0] }}</small>
      </div>
      <div class="col-12 px-0 lg:px-2">
        <div class="flex flex-column mb-3">
          <p class="mt-0">Have you verified a valid government-issue ID?</p>
          <div class="flex">
            <div class="p-field-radiobutton mr-4">
              <RadioButton id="verified1" name="city" :value="true" v-model="data.is_verified_issue_id"/>
              <label for="verified1">Yes</label>
            </div>
            <div class="p-field-radiobutton mr-4">
              <RadioButton id="verified2" name="city" :value="false" v-model="data.is_verified_issue_id"/>
              <label for="verified2">No</label>
            </div>
          </div>
        </div>
        <small v-if="errors.is_verified_issue_id" class="p-error">{{ errors.is_verified_issue_id[0] }}</small>
      </div>
      <div class="col-12 px-0 lg:px-2">
        <div class="flex flex-column mb-3">
          <p class="mt-0">Does the inventory match the products/services sold?</p>
          <div class="flex">
            <div class="p-field-radiobutton mr-4">
              <RadioButton id="inventory1" @change="changeData()" name="city" :value="true" v-model="data.is_inventory_match_sold"/>
              <label for="inventory1">Yes</label>
            </div>
            <div class="p-field-radiobutton mr-4">
              <RadioButton id="inventory2" name="city" @change="changeData()" :value="false" v-model="data.is_inventory_match_sold"/>
              <label for="inventory2">No</label>
            </div>
          </div>
        </div>
        <small v-if="errors.is_inventory_match_sold" class="p-error">{{ errors.is_inventory_match_sold[0] }}</small>
      </div>
      <div v-if="!data.is_inventory_match_sold" class="col-12 px-0 lg:px-2">
        <div class="w-full mb-2 flex flex-column">
          <label for="Comment" class="mb-2">Comment</label>
          <Textarea
              id="Comment"
              v-model="data.inventory_match_sold_comment"
              placeholder="Please write comment."
              type="text"
              rows="3"
              :class="{'p-invalid': errors.inventory_match_sold_comment }"
              aria-describedby="inventory_match_sold_comment_help"
          />
          <small id="inventory_match_sold_comment_help" v-if="errors.inventory_match_sold_comment" class="p-error">{{ errors.inventory_match_sold_comment[0] }}</small>
        </div>
      </div>
      <div class="col-12 px-0 lg:px-2">
        <div class="step-header mb-3">Verification</div>
        <div class="gray-text mb-4 line-height-3">I verify that (i) I have physically inspected
          the business premises
          or I verified the merchant via the phone which included obtatining a copy of a valid
          picture ID, which I have included with the application and that (ii) the information
          satated in this Site Inspection Form correct to the best of my knowledge and is as
          presented to me by the Merchant.
        </div>
        <div class="p-field-checkbox">
          <Checkbox id="confirmation" :binary="true" v-model="data.is_agree_terms"/>
          <label for="confirmation">Yes, I agree to these terms</label>
        </div>
      </div>
    </div>
    <template #footer>
      <small v-if="error" class="p-error">{{ error }}</small>
      <div class="flex justify-content-end">
        <Button :disabled="isLoading" label="Cancel" @click="hide()" class="p-button-outlined p-button-lg hidden sm:block"/>
        <Button :disabled="isLoading" label="Submit" @click="saveData()" class="p-button-lg sm:px-6 flex-1 sm:flex-initial"/>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import Checkbox from 'primevue/checkbox';
import RadioButton from 'primevue/radiobutton';
import ApplicationService from "@/services/api-calls/application.service";
import ProgressBar from 'primevue/progressbar';
import FormHelper from "@/services/form.helper";
import Textarea from 'primevue/textarea';

export default {
  name: 'MerchantVerification',
  components: {
    InputText,
    Button,
    Dialog,
    Checkbox,
    RadioButton,
    ProgressBar,
    Textarea
  },
  updated() {
    if (this.isActive !== this.isOpen) {
      this.isOpen = this.isActive;
    }
  },
  props: {
    application: Object,
    isActive: Boolean,
  },
  watch: {
    isOpen(isOpen) {
      if (isOpen) {
        this.getData();
      }
    }
  },
  methods: {
    hide() {
      this.data = {is_agree_terms: false}
      this.$emit('close');
    },
    changeData() {
      if (this.data.is_conducted_in_person) {
        delete this.data.acquisition_type_id;
      }
      if (this.data.is_inventory_match_sold) {
        delete this.data.inventory_match_sold_comment;
      }
    },
    async getData() {
      this.isLoading = true;
      const result = await ApplicationService.getFormData(this.application.merchant_number, 'survey')
      this.data = result.data ?? {is_agree_terms: false}
      this.error = result.error ? FormHelper.convertGatewayError(result) :  null;
      this.isLoading = false;
    },
    async saveData() {
      this.isLoading = true;
      const result = await ApplicationService.submitForm(this.application.merchant_number, 'survey', {...this.data})
      this.errors = result.errors ?? {};
      this.error = result.error ? FormHelper.convertGatewayError(result) :  null;
      this.isLoading = false;
      if (result.application) {
        this.$emit('update', result.application);
        this.hide();
      }
    }
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      error: null,
      data: {is_agree_terms: false},
      errors: {}
    };
  },
};
</script>