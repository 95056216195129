<template>
  <full-screen-modal-with-header :init-data="{previously_accepted_payment_cards: false, have_terminated: false, business_is_open: false, business_is_seasonal: false}"
                                 v-slot:default="props" v-if="this.isActive" :active="this.isActive" :merchant-number="this.application.merchant_number"
                                 title="Merchant Application" prev="general" current="business" next="profile">
    <div id="new-merchant-app-2">
      <div class="grid m-0 flex flex-1">
        <div class="p-0 col-12 lg:hidden">
          <div class="step-description step-description-mobile flex flex-column lg:hidden">
            <div class="flex justify-content-between align-items-center">
              <div class="step flex mb-0">
                <div class="step-number step-number-active">2</div>
                <div class="step-name">Legal Entity Information</div>
              </div>
              <div class="step-header">Step 2 of 5</div>
            </div>
            <p class="gray-text mb-0">Enter the legal entity information and tell us more about your
              business.</p>
          </div>
        </div>
        <div class="hidden lg:flex lg:col-3 p-0">
          <div class="step-description hidden lg:flex">
            <div>
              <div class="step-header mb-2">Step 2 of 5</div>
              <p class="gray-text mt-2 mb-5">Enter the legal entity information and tell us more about
                your business.</p>
              <FormSteps
                  :completed_forms="this.application.completed_steps.completed_forms"
                  current="business"
                  @open="this.$emit('open', $event)"
              />
            </div>
          </div>
        </div>
        <div class="lg:col-9 col-12 px-0">
          <div class="step-body mt-2 lg:mt-0">
            <div class="step-header mb-5 px-0 lg:px-2">
              Please enter the legal business entity information.
            </div>

            <div class="grid mx-0 mb-3">
              <div class="col-12 lg:col-6 px-0 lg:px-2">
                <div class="p-field mb-5 flex flex-column">
                  <label for="ownershipType" class="mb-2">Ownership Type</label>
                  <Dropdown
                      v-model="props.data.ownership_type"
                      :options="getCompanyTypes(props.data.ownership_type, props.params.options.companyTypes ?? [])"
                      optionLabel="name"
                      optionValue="value"
                      id="ownershipType"
                      placeholder="Ownership Type"
                      :disabled="props.params.loading"
                      v-on:change="props.data = ownershipType($event.value, props.data)"
                      :class="{'p-invalid': props.params.errors.ownership_type }"
                      aria-describedby="ownership_type_help"
                  />
                  <small id="ownership_type" v-if="props.params.errors.ownership_type" class="p-error">{{ props.params.errors.ownership_type[0] }}</small>
                </div>
                <div v-if="props.data.ownership_type != 1" class="flex mb-5">
                  <Checkbox :binary="true" :disabled="props.params.loading" @input="props.data = legalNameSameDba($event, props.data)" v-model="props.data.legal_name_same_dba" class="mr-2"/>
                  <div>My legal name listed above is the same on DBA</div>
                </div>
                <div v-if="props.data.ownership_type != 1 && !props.data.legal_name_same_dba" class="p-field mb-2 flex flex-column">
                  <label for="legalName" class="mb-2">Legal Name</label>
                  <InputText
                      id="legalName"
                      v-model="props.data.business_legal_name"
                      :disabled="props.params.loading"
                      placeholder="Legal Name"
                      type="text"
                      :class="{'p-invalid': props.params.errors.business_legal_name }"
                      aria-describedby="business_legal_name_help"
                  />
                  <small id="business_legal_name_help" v-if="props.params.errors.business_legal_name" class="p-error">{{ props.params.errors.business_legal_name[0] }}</small>
                </div>
                <div v-if="props.data.ownership_type == 1" class="p-field mb-2 flex flex-column">
                  <label for="legalFirstName" class="mb-2">Legal First Name</label>
                  <InputText
                      id="legalFirstName"
                      v-model="props.data.legal_first_name"
                      :disabled="props.params.loading"
                      placeholder="Legal First Name"
                      type="text"
                      :class="{'p-invalid': props.params.errors.legal_first_name }"
                      aria-describedby="legal_first_name_help"
                  />
                  <small id="legal_first_name_help" v-if="props.params.errors.legal_first_name" class="p-error">{{ props.params.errors.legal_first_name[0] }}</small>
                </div>
                <div v-if="props.data.ownership_type == 1" class="p-field mb-2 flex flex-column">
                  <label for="legalLastName" class="mb-2">Legal Last Name</label>
                  <InputText
                      id="legalLastName"
                      v-model="props.data.legal_last_name"
                      :disabled="props.params.loading"
                      placeholder="Legal Last Name"
                      type="text"
                      :class="{'p-invalid': props.params.errors.legal_last_name }"
                      aria-describedby="legal_last_name_help"
                  />
                  <small id="legal_last_name_help" v-if="props.params.errors.legal_last_name" class="p-error">{{ props.params.errors.legal_last_name[0] }}</small>
                </div>
              </div>
              <div v-if="props.data.ownership_type == 1" class="flex mb-3 col-12 px-0 lg:px-2">
                <Checkbox :binary="true" :disabled="props.params.loading" v-model="props.data.federal_tax_is_ssn" class="mr-2"/>
                <div>Is SSN</div>
                <small v-if="props.params.errors.federal_tax_is_ssn" class="p-error">{{ props.params.errors.federal_tax_is_ssn[0] }}</small>
              </div>
            </div>
            <div class="grid mx-0 mb-4">
              <div class="col-12 lg:col-6 px-0 lg:px-2 mb-2">
                <div class="p-field mb-2 flex flex-column">
                  <label for="taxId" class="mb-2">Federal Tax ID</label>
                  <InputMask
                      v-if="props.data.federal_tax_is_ssn"
                      id="taxId"
                      type="text"
                      :unmask="true"
                      v-model="federal_tax_id"
                      v-on:change="props.data.federal_tax_id = federal_tax_id"
                      :disabled="props.params.loading"
                      placeholder="__-_______"
                      mask="999-99-9999"
                      :class="{'p-invalid': props.params.errors.federal_tax_id }"
                      aria-describedby="federal_tax_id_help"
                  />
                  <InputMask
                      v-else
                      id="taxId"
                      type="text"
                      :unmask="true"
                      v-model="federal_tax_id"
                      v-on:change="props.data.federal_tax_id = federal_tax_id"
                      :disabled="props.params.loading"
                      placeholder="__-_______"
                      mask="99-9999999"
                      :class="{'p-invalid': props.params.errors.federal_tax_id }"
                      aria-describedby="federal_tax_id_help"
                  />
                  <span v-if="props.data.federal_tax_id && -1 < props.data.federal_tax_id.indexOf('*****')"><label v-once>Previous saved: {{ props.data.federal_tax_id }}</label></span>
                  <small id="federal_tax_id_help" v-if="props.params.errors.federal_tax_id" class="p-error">{{ props.params.errors.federal_tax_id[0] }}</small>
                </div>
              </div>
              <div class="col-12 lg:col-3 px-0">
                <div class="p-field mb-2 flex flex-column">
                  <label for="state" class="mb-2">State</label>
                  <Dropdown
                      v-model="props.data.state"
                      :options="props.params.options.states ?? []"
                      :disabled="props.params.loading"
                      optionLabel="name"
                      optionValue="value"
                      id="state"
                      placeholder="Select"
                      :class="{'p-invalid': props.params.errors.state }"
                      aria-describedby="state_help"
                  />
                  <small id="state_help" v-if="props.params.errors.state" class="p-error">{{ props.params.errors.state[0] }}</small>
                </div>
              </div>
            </div>

            <div class="grid mx-0">
              <div class="flex mb-3 col-12 px-0 lg:px-2">
                <Checkbox :binary="true" :disabled="props.params.loading" v-model="props.data.previously_accepted_payment_cards" class="mr-2"/>
                <div>I currently accept or have previously accepted payment cards</div>
                <small v-if="props.params.errors.previously_accepted_payment_cards" class="p-error">{{ props.params.errors.previously_accepted_payment_cards[0] }}</small>
              </div>
              <div class="flex mb-3 col-12 px-0 lg:px-2">
                <Checkbox :binary="true" :disabled="props.params.loading" v-model="props.data.have_terminated" @input="props.data = this.haveTerminated($event, props.data)" class="mr-2"/>
                <div>I have been terminated/identified by Risk Monitoring</div>
                <small v-if="props.params.errors.have_terminated" class="p-error">{{ props.params.errors.have_terminated[0] }}</small>
              </div>
              <div v-if="props.data.have_terminated" class="flex mb-3 col-12 lg:col-9 px-0 lg:px-2">
                <div class="w-full mb-2 flex flex-column">
                  <label for="terminationReason" class="mb-2">Reason</label>
                  <Textarea
                      id="terminationReason"
                      :disabled="props.params.loading"
                      v-model="props.data.reason_terminated"
                      placeholder="Please explain why you were terminated/identified by Risk Monitoring."
                      type="text"
                      rows="3"
                      :class="{'p-invalid': props.params.errors.reason_terminated }"
                      aria-describedby="reason_terminated_help"
                  />
                  <small id="reason_terminated_help" v-if="props.params.errors.reason_terminated" class="p-error">{{ props.params.errors.reason_terminated[0] }}</small>
                </div>
              </div>
              <div class="flex mb-3 col-12 px-0 lg:px-2">
                <Checkbox :binary="true" :disabled="props.params.loading" v-model="props.data.business_is_open" class="mr-2"/>
                <div>My business is currently open</div>
                <small v-if="props.params.errors.business_is_open" class="p-error">{{ props.params.errors.business_is_open[0] }}</small>
              </div>
              <div class="flex mb-3 col-12 px-0 lg:px-2">
                <Checkbox :binary="true" :disabled="props.params.loading" v-model="props.data.business_is_seasonal" @input="props.data = isSeasonal($event, props.data)" class="mr-2"/>
                <div>My business is seasonal</div>
                <small v-if="props.params.errors.business_is_seasonal" class="p-error">{{ props.params.errors.business_is_seasonal[0] }}</small>
              </div>
              <div v-if="props.data.business_is_seasonal" class="flex col-12 xl:col-6 px-0 lg:px-2">
                <div class="w-full mb-2 flex flex-column">
                  <label class="mb-3">Select the months in which you are open for business:</label>
                  <div class="grid px-0 flex-wrap">
                    <div v-for="month in this.months" v-bind:key="month.name" class="col-3 md:col-2">
                      <div @click="props.data.schedule[month.name] = !props.data.schedule[month.name]" class="month-selection" :class="{'month-selection-active': props.data.schedule[month.name] }">{{month.title}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </full-screen-modal-with-header>
</template>

<script>
import FullScreenModalWithHeader from '@/components/FullScreenModalWithHeader';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import Textarea from 'primevue/textarea';
import FormSteps from './FormSteps';
import InputMask from 'primevue/inputmask';

export default {
  components: {
    FullScreenModalWithHeader,
    InputText,
    Dropdown,
    Checkbox,
    Textarea,
    FormSteps,
    InputMask
  },
  props: {
    application: Object,
    isActive: Boolean,
  },
  methods: {
    ownershipType(value, data) {
     if (1 == value) {
       delete data.business_legal_name
       delete data.legal_name_same_dba
       data.federal_tax_is_ssn = false
     } else {
       delete data.legal_last_name
       delete data.legal_first_name
       delete data.federal_tax_is_ssn
       data.legal_name_same_dba = false
     }
     return data;
    },
    legalNameSameDba(value, data) {
      if (value) {
        delete data.business_legal_name
      }
      return data;
    },
    haveTerminated(value, data) {
      if (!value) {
          delete data.reason_terminated
      }
      return data;
    },
    isSeasonal(value, data) {
      if (!value) {
        delete data.schedule
      } else {
        data.schedule = {
          january: false, february: false,  march: false, april: false, may: false, june: false,
          july: false, august: false,  september: false, october: false, november: false, december: false,
        }
      }
      return data;
    },
    getCompanyTypes(value, options) {
      return options.map(function (option) {
        option.value = Number.parseInt(option.value);
        return option;
      });
    }
  },
  data() {
    return {
      federal_tax_id: '',
      months: [
        {name: 'january', title: 'Jan'},
        {name: 'february', title: 'Feb'},
        {name: 'march', title: 'Mar'},
        {name: 'april', title: 'Apr'},
        {name: 'may', title: 'May'},
        {name: 'june', title: 'Jun'},
        {name: 'july', title: 'Jul'},
        {name: 'august', title: 'Aug'},
        {name: 'september', title: 'Sep'},
        {name: 'october', title: 'Oct'},
        {name: 'november', title: 'Nov'},
        {name: 'december', title: 'Dev'},
      ],
    };
  },
};
</script>