<template>
  <h1 v-if="quote" class="page-title hidden md:flex mb-5">{{ quote.title }}</h1>

  <div class="col-8 col-offset-2 p-fluid">
    <div @click="goOrders" class="cursor-pointer quote-button quote-button-prev"><i class="pi pi-fw pi-chevron-left"/>Previous</div>
    <div @click="downloadQuote" class="cursor-pointer quote-button quote-button-active">
      <div v-if="downloading" class="inline mr-2"><progress-spinner style="width:20px;height:16px"/></div>
      <i v-else class="pi pi-fw pi-download mr-2"/>Download PDF
    </div>
    <div @click="sendQuote" class="cursor-pointer quote-button quote-button-active">
      <div v-if="sending" class="inline mr-2"><progress-spinner style="width:20px;height:16px"/></div>
      <i v-else class="pi pi-fw pi-envelope mr-2"/>Email to Merchant
    </div>
    <Message @close="success = null" severity="success" v-if="success">{{ success }}</Message>
    <Message @close="error = null" severity="error" v-if="error">{{ error }}</Message>
    <div v-if="isLoading" class="text-center"><progress-spinner/></div>
    <div id="page" class="p-0" v-else-if="quote" v-html="quote.html"></div>
  </div>
</template>

<script>

import OrdersService from "@/services/api-calls/orders.service";
import ProgressSpinner from 'primevue/progressspinner';
import Message from "primevue/message";

export default {
  props: {
    orderId: Number
  },
  components: {
    ProgressSpinner,
    Message,
  },
  created() {
    this.getQuote();
  },
  data() {
    return {
      quote: null,
      success: null,
      error: null,
      isLoading: false,
      downloading: false,
      sending: false,
    };
  },
  methods: {
    async getQuote() {
      this.isLoading = true;

      const data = await OrdersService.getQuote(this.orderId);
      this.quote = data.quote ?? []
      this.error = data.error ?? null
      this.isLoading = false;
    },
    async downloadQuote() {
      if (!this.downloading) {
        this.downloading = true;
        await OrdersService.downloadQuote(this.orderId);
        this.downloading = false;
      }
    },
    async sendQuote() {
      if (!this.sending) {
        this.sending = true;
        const result = await OrdersService.sendQuote(this.orderId);
        this.success = result.success ? 'Email sent' : null;
        this.error = result.error ?? null
        this.sending = false;
      }
    },
    goOrders() {
      this.$router.go(-1)
    },
  },
}
</script>
