<template>
  <div class="grid mb-3">
    <div class="col-12 lg:col-6">
      <div class="subheading">Select Parse Pay Account</div>
      <p class="gray-text">We first need to link this application to a BackOffice account.
        If you cannot locate the account, create a new one.</p>
    </div>
    <div class="col-12 lg:col-6 flex flex-column">
      <label class="mb-2" for="select-account-dropdown">Select Account</label>
      <div v-if="isLoading" class="text-center"><progress-spinner/></div>
      <div class="p-error" v-if="error">{{ error }}</div>
      <Dropdown
          v-if="customers.length"
          v-model="selectedCustomer"
          :options="customers"
          optionLabel="name"
          optionValue="id"
          inputId="select-account"
          id="select-account-dropdown"
          placeholder="Select"
          @change="this.$emit('update:customer', $event.value);"
      />
      <div v-else-if="!isLoading" class="text-center p-error">No customers found</div>
    </div>
  </div>
</template>

<script>

import Dropdown from 'primevue/dropdown';
import ProgressSpinner from 'primevue/progressspinner';
import UsersService from "@/services/api-calls/users.service";

export default {
  name: "ParsePosCustomers",
  components: {
    Dropdown,
    ProgressSpinner,
  },
  props: {
    customer: String
  },
  updated() {
    this.selectedCustomer = this.customer
  },
  emits: ['update:customer'],
  async mounted() {
    this.isLoading = true;
    const result = await UsersService.getParsePosUsers();
    if (result.success) {
      this.customers = result.users;
    } else {
      this.error = result.error
    }
    this.isLoading = false;
  },
  data() {
    return {
      selectedCustomer: this.customer,
      error: null,
      customers: [],
      isLoading: false,
    }
  },
}

</script>