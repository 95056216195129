<template>
  <DataTable :value="users" :loading="loading">
    <Column field="email" header="Email">
      <template #body="slotProps">
        <div class="p-3">
          {{slotProps.data.email}}
        </div>
      </template>
    </Column>
    <Column field="name" header="Name">
      <template #body="slotProps">
        <div class="p-3">
          {{slotProps.data.name}}
        </div>
      </template>
    </Column>
    <Column field="confirmed" header="Confirmed">
      <template #body="slotProps">
        <div class="p-3">
         <i v-if="slotProps.data.confirmed" class="p-success pi pi-check"></i>
          <i v-else class="p-error pi pi-times"></i>
        </div>
      </template>
    </Column>
    <Column field="last_login" header="Last login">
      <template #body="slotProps">
        <div class="p-3">
          {{slotProps.data.last_login}}
        </div>
      </template>
    </Column>
  </DataTable>
</template>

<script>
import UsersService from "@/services/api-calls/users.service";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

export default {
  components: {
    Column,
    DataTable
  },
  created() {
    this.getItems();
  },
  data() {
    return {
      loading: false,
      users: [],

    };
  },
  methods: {
    async getItems() {
      this.loading = true;
      const data = await UsersService.getUsers();
      this.users = data.users ?? []
      this.loading = false;
    }
  },
}
</script>
