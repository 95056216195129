import AuthService from './auth.service';

const getHeaders = () => {
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.access_token) {
        return { Authorization: 'Bearer ' + user.access_token };
    } else {
        return {};
    }
}

export default function authHeader() {
    let user = JSON.parse(localStorage.getItem('user'));

    if (!user || !user.access_token) {
        return {};
    }

    if (Math.round(Date.now() / 1000) >= user.expires_at) {
        return AuthService.login({refresh_token: user.refresh_token, grant_type: 'refresh_token'}).then(
            () => {
                return getHeaders();
            },
            () => {
              AuthService.logout()
            }
        );
    } else {
        return { Authorization: 'Bearer ' + user.access_token };
    }


}