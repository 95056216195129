<template>
  <div id="page">
    <h1 class="page-title hidden md:flex mb-5">{{ title }}</h1>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Page",
  props: {
    title: String
  }
}
</script>