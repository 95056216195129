<template>
  <div id="applications-list-table">
    <DataTable :value="applications" responsiveLayout="scroll"
               :lazy="true" :paginator="true" :rows="itemsPerPage" :totalRecords="total"
               :loading="loading"
               @page="onPage($event)"
               paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
               currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
      <template #empty>
          <div class="p-3">
              No Applications found
          </div>
      </template>
      <Column field="name" header="Business Name">
        <template #body="slotProps">
          <div class="flex h-full align-items-center">
            <div v-if="slotProps.data.status === 'submitted'"
                 class="table-color-highlight-bar table-color-highlight-bar-success mr-3"/>
            <div v-if="slotProps.data.status === 'inProgress'"
                 class="table-color-highlight-bar table-color-highlight-bar-warning mr-3"/>
            <div v-if="slotProps.data.status === 'pending'"
                 class="table-color-highlight-bar table-color-highlight-bar-primary mr-3"/>
            <div>{{slotProps.data.name_of_business}}</div>
          </div>
        </template>
      </Column>
      <Column class="mobile-hide" field="primaryContact" header="Primary Contact">
        <template #body="slotProps">
          <div class="p-3">
            {{slotProps.data.name}}
          </div>
        </template>
      </Column>
      <Column class="mobile-hide" field="mid" header="MID">
        <template #body="slotProps">
          <div class="p-3">
            {{slotProps.data.merchant_number}}
          </div>
        </template>
      </Column>
      <Column field="status" header="Status">
        <template #body="slotProps">
          <div class="flex justify-content-between align-items-center p-3">
            <Button v-if="slotProps.data.status === 'submitted'" type="button"
                    class="p-button-sm p-button-success table-btn"
                    label="Submitted"/>
            <Button v-if="slotProps.data.status === 'inProgress'" type="button"
                    class="p-button-sm p-button-warning table-btn"
                    label="In Progress"/>
            <Button v-if="slotProps.data.status === 'pending'" type="button" class="p-button-sm table-btn"
                    label="Pending"/>
            <i @click="showModal(slotProps.data)" class="pi pi-fw pi-chevron-right table-icon cursor-pointer"/>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
  <merchant-app-modal
      :application="selectedApplication"
      v-show="selectedApplication"
      @close="closeModal"
  />
</template>

<script>
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Button from 'primevue/button';
import ApplicationService from "@/services/api-calls/application.service";
import MerchantAppModal from '@/components/Application/MainModal';

export default {
  components: {
    Column,
    Button,
    DataTable,
    MerchantAppModal
  },
  props: {
    filter: Object,
    updateTrigger: Boolean,
  },
  created() {
    this.getItems();
  },
  methods: {
    showModal(selectedApplication) {
      this.selectedApplication = selectedApplication;
    },
    closeModal() {
      this.selectedApplication = null;
    },
    onPage(event) {
      this.currentPage = event.page;
      this.getItems();
    },
    async getItems() {
      this.loading = true;
      const data = await ApplicationService.getAll(
          this.itemsPerPage,
          this.currentPage + 1,
          (this.filter && this.filter.value && this.filter.value.length > 3) ? this.filter : {name: null, value: null}
      );
      if (data.success) {
        this.applications = data.applications
        this.total = data.total
      } else {
        this.error = data.error
      }

      this.loading = false;
    }
  },
  watch: {
    filter() {
      this.getItems();
    },
    updateTrigger() {
      this.getItems();
    }
  },
  data() {
    return {
      error: null,
      selectedApplication: null,
      loading: true,
      total: 0,
      first: 1,
      last: 0,
      currentPage: 0,
      itemsPerPage: 7,
      applications: [],
    };
  },
};
</script>
            