class FormHelper {
    convertOptions(FormOptions) {
        let result = {}
        for (const [optionsType, options] of Object.entries(FormOptions)) {
            result[optionsType] = [];
            for (const [name, value] of Object.entries(options)) {
                result[optionsType].push({name: name, value: value});
            }
        }
        return result;
    }

    convertGatewayError(result) {
        let error = result.error;
        if ('Gateway error' === result.error) {
            for (let key in result.errors) {
                error += ' ' + result.errors[key].errorMessage
            }
        }
        return error;
    }
}

export default new FormHelper();