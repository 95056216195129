<template>
    <div class="grid m-0 flex flex-1">
      <div class="col-12 lg:col-6 px-0 lg:px-2">
        <div class="p-field mb-2 flex flex-column">
          <label for="firstName" class="mb-2">First Name</label>
          <InputText
              id="firstName"
              type="text"
              v-model="contact.first_name"
              v-on:input="this.$emit('updateContact', this.contact)"
              :class="{'p-invalid': errors.first_name }"
              aria-describedby="first_name_help"
          />
          <small id="first_name_help" v-if="errors.first_name" class="p-error">{{ errors.first_name[0] }}</small>
        </div>
      </div>
      <div class="col-12 lg:col-6 px-0 lg:px-2 mb-2">
        <div class="p-field mb-2 flex flex-column">
          <label for="lastName" class="mb-2">Last Name</label>
          <InputText
              id="lastName"
              type="text"
              v-model="contact.last_name"
              v-on:input="this.$emit('updateContact', this.contact)"
              :class="{'p-invalid': errors.last_name }"
              aria-describedby="last_name_help"
          />
          <small id="last_name_help" v-if="errors.last_name" class="p-error">{{ errors.last_name[0] }}</small>
        </div>
      </div>
      <div class="col-12 px-0 lg:px-2">
        <div class="flex flex-column mb-3 px-0 lg:px-2">
          <p class="mt-0">What is the role of this contact (select all that apply)</p>
          <div class="flex">
            <div class="p-field-checkbox mr-4">
              <Checkbox id="Signer" v-model="contact.types" @change="this.$emit('updateContact', this.contact)" :value="1"/>
              <label for="Signer">Signer</label>
            </div>
            <div class="p-field-checkbox mr-4">
              <Checkbox id="Owner" v-model="contact.types" @change="this.$emit('updateContact', this.contact)" :value="2"/>
              <label for="Owner">Owner</label>
            </div>
            <div class="p-field-checkbox mr-4">
              <Checkbox id="General" v-model="contact.types"  @change="this.$emit('updateContact', this.contact)" :value="3"/>
              <label for="General">General Contact</label>
            </div>
            <small v-if="errors.types" class="p-error">{{ errors.types[0] }}</small>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-6 px-0 lg:px-2">
        <div class="p-field mb-2 flex flex-column">
          <label for="email" class="mb-2">Email Address</label>
          <InputText
              id="email"
              placeholder="Example: john@gmail.com"
              type="text"
              v-model="contact.email"
              v-on:input="this.$emit('updateContact', this.contact)"
              :class="{'p-invalid': errors.email }"
              aria-describedby="email_help"
          />
          <small id="email_help" v-if="errors.email" class="p-error">{{ errors.email[0] }}</small>
        </div>
      </div>
      <div class="col-12 lg:col-6 px-0 lg:px-2">
        <div class="p-field mb-2 flex flex-column">
          <label for="phone" class="mb-2">Phone Number</label>
          <InputMask
              id="phone"
              placeholder="(___) ___-____"
              type="text"
              mask="(999) 999-9999"
              v-model="contact.phone"
              v-on:input="this.$emit('updateContact', this.contact)"
              :class="{'p-invalid': errors.phone }"
              aria-describedby="phone_help"
          />
          <small id="phone_help" v-if="errors.phone" class="p-error">{{ errors.phone[0] }}</small>
        </div>
      </div>
      <div class="col-12 lg:col-6 px-0 lg:px-2">
        <div class="p-field mb-2 flex flex-column">
          <label for="birthdate" class="mb-2">Date of Birth</label>
          <InputMask
              id="birthdate"
              mask="99/99/9999"
              slotChar="mm/dd/yyyy"
              placeholder="__/__/____"
              type="text"
              v-model="contact.dob"
              v-on:input="this.$emit('updateContact', this.contact)"
              :class="{'p-invalid': errors.dob }"
              aria-describedby="dob_help"
          />
          <small id="dob_help" v-if="errors.dob" class="p-error">{{ errors.dob[0] }}</small>
        </div>
      </div>
      <div class="col-12 lg:col-6 px-0 lg:px-2">
        <div class="p-field mb-2 flex flex-column">
          <label for="ssn" class="mb-2">SSN</label>
          <InputMask
              id="ssn"
              mask="999-99-9999"
              placeholder="___-__-____"
              type="text"
              v-model="contact.ssn"
              v-on:input="this.$emit('updateContact', this.contact)"
              :class="{'p-invalid': errors.ssn }"
              aria-describedby="ssn_help"
          />
          <span v-if="contact.ssn && -1 < contact.ssn.indexOf('*****')"><label v-once>Previous saved: {{ contact.ssn }}</label></span>
          <small id="ssn_help" v-if="errors.ssn" class="p-error">{{ errors.ssn[0] }}</small>
        </div>
      </div>
      <div class="col-12 lg:col-6 px-0 lg:px-2">
        <div class="p-field mb-2 flex flex-column">
          <label for="businessWeb" class="mb-2">Home Address</label>
          <InputText
              id="businessWeb"
              type="text"
              v-model="contact.address.street"
              v-on:input="this.$emit('updateContact', this.contact)"
              :class="{'p-invalid': errors['address.street'] }"
              aria-describedby="street_help"
          />
          <small id="street_help" v-if="errors['address.street']" class="p-error">{{ errors['address.street'][0] }}</small>
        </div>
      </div>
      <div class="col-12 lg:col-6 px-0 lg:px-2">
        <div class="grid mx-0">
          <div class="col-12 lg:col-6 px-0 lg:px-2">
            <div class="p-field mb-2 flex flex-column">
              <label for="city" class="mb-2">City</label>
              <InputText
                  id="city"
                  type="text"
                  v-model="contact.address.city"
                  v-on:input="this.$emit('updateContact', this.contact)"
                  :class="{'p-invalid': errors['address.city'] }"
                  aria-describedby="city_help"
              />
              <small id="city_help" v-if="errors['address.city']" class="p-error">{{ errors['address.city'][0] }}</small>
            </div>
          </div>
          <div class="col-12 lg:col-3 px-0 lg:px-2">
            <div class="p-field mb-2 flex flex-column">
              <label for="state" class="mb-2">State</label>
              <Dropdown
                  v-model="contact.address.state"
                  :options="options.states"
                  optionLabel="name"
                  optionValue="value"
                  id="state"
                  placeholder="Select"
                  @change="this.$emit('updateContact', this.contact)"
                  :class="{'p-invalid': errors['address.state'] }"
                  aria-describedby="state_help"
              />
              <small id="state_help" v-if=" errors['address.state']" class="p-error">{{ errors['address.state'][0] }}</small>
            </div>
          </div>
          <div class="col-12 lg:col-3 px-0 lg:px-2 mb-2">
            <div class="p-field mb-2 flex flex-column">
              <label for="streetAddress" class="mb-2">Zip</label>
              <InputText
                  id="streetAddress"
                  type="text"
                  placeholder="Select"
                  v-model="contact.address.zip"
                  @change="this.$emit('updateContact', this.contact)"
                  :class="{'p-invalid': errors['address.zip'] }"
                  aria-describedby="zip_help"
              />
              <small id="zip_help" v-if="errors['address.zip']" class="p-error">{{ errors['address.zip'][0] }}</small>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="flex flex-column mb-3 px-0 lg:px-2">
          <p class="mt-0">Is the contact the primary contact for the business?</p>
          <div class="flex">
            <div class="p-field-radiobutton mr-4">
              <RadioButton id="is_primary1" @change="this.$emit('updateContact', this.contact)" :value="true" v-model="contact.is_primary"/>
              <label for="is_primary1">Yes</label>
            </div>
            <div class="p-field-radiobutton mr-4">
              <RadioButton id="is_primary2" @change="this.$emit('updateContact', this.contact)" :value="false" v-model="contact.is_primary"/>
              <label for="is_primary2">No</label>
            </div>
            <small v-if="errors.is_primary" class="p-error">{{ errors.is_primary[0] }}</small>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="flex flex-column mb-3 px-0 lg:px-2">
          <p class="mt-0">Do you want to assign this user access to Compass for reporting?</p>
          <div class="flex">
            <div class="p-field-radiobutton mr-4">
              <RadioButton id="is_compass1" @change="this.$emit('updateContact', this.contact)" :value="true" v-model="contact.is_compass"/>
              <label for="is_compass1">Yes</label>
            </div>
            <div class="p-field-radiobutton mr-4">
              <RadioButton id="is_compass2" @change="this.$emit('updateContact', this.contact)" :value="false" v-model="contact.is_compass"/>
              <label for="is_compass2">No</label>
            </div>
            <small v-if="errors.is_compass" class="p-error">{{ errors.is_compass[0] }}</small>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-6 px-0 lg:px-2 mb-2">
        <div class="p-field mb-2 flex flex-column">
          <label for="title" class="mb-2">Title</label>
          <InputText
              id="title"
              type="text"
              v-model="contact.title"
              @change="this.$emit('updateContact', this.contact)"
              :class="{'p-invalid': errors.title }"
              aria-describedby="title_help"
          />
          <small id="title_help" v-if="errors.title" class="p-error">{{ errors.title[0] }}</small>
        </div>
      </div>
      <div class="col-12 lg:col-6 px-0 lg:px-2 mb-4">
        <div class="p-field flex flex-column">
          <label for="country" class="mb-2">Country of Citizenship</label>
          <Dropdown
              :options="options.countries"
              optionLabel="name"
              optionValue="value"
              id="country"
              placeholder="Select"
              v-model="contact.citizenship_code"
              @change="this.$emit('updateContact', this.contact)"
              :class="{'p-invalid': errors.citizenship_code }"
              aria-describedby="citizenship_code_help"
          />
          <small id="citizenship_code_help" v-if="errors.citizenship_code" class="p-error">{{ errors.citizenship_code[0] }}</small>
        </div>
      </div>
      <div class="col-12">
        <div class="grid mx-0 mb-3">
          <div class="col-12 lg:col-4 px-0 lg:px-2 mb-3">
            <div class="step-header mb-2">Ownership Percentage</div>
            <div class="gray-text">Select the ownership percentage. Only add contacts with >25% ownership.
            </div>
          </div>
          <div class="col-12 lg:col-8 flex px-0 lg:px-2 mb-3">
            <div id="slabel">{{ contact.ownership_amount }}%</div>
            <div class="flex flex-1 align-items-center">
              <div class="w-full">
                <Slider :disabled="false" v-model.number="contact.ownership_amount"/>
              </div>
              <small v-if="errors.ownership_amount" class="p-error">{{ errors.ownership_amount[0] }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import RadioButton from 'primevue/radiobutton';
import Slider from 'primevue/slider';
import InputMask from 'primevue/inputmask';

export default {
  name: 'ApplicationsListToolbar',
  components: {
    InputText,
    Dropdown,
    Checkbox,
    RadioButton,
    Slider,
    InputMask
  },
  props: {
    options: Object,
    errors: Object,
    contactData: Object
  },
  data() {
    return {
      data: null,
      isOpen: false,
      states: [],
      contact: this.contactData
    };
  },
};
</script>