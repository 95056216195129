<template>
  <div>
    <Dialog
        id="signatures-start-modal"
        header="Electronic Signatures"
        v-model:visible="isOpen"
        @hide="hide()"
        :breakpoints="{'960px': '75vw', '769px': '95vw'}"
        :style="{width: '45vw'}"
    >
      <div v-if="contact" class="text-center">
        <h2>Welcome to Parse Pay</h2>
        <p class="p-2 signs-hello">Welcome to Parse Pay electronic signature capture wizard. To begin, please confirm you identification information below.</p>
        <div class="grid">
          <div class="hidden lg:block lg:col-3">
          </div>
          <div class="signs-contact col-12 lg:col-6">
            <p>{{ contact.name }}</p>
            <p>{{ contact.street }} {{ contact.city }}, {{ contact.state }} {{ contact.zip }}</p>
            <p>Ownership: {{ contact.ownershipAmount }}%</p>
          </div>
        </div>
        <div class="grid">
          <div class="hidden lg:block lg:col-3">
          </div>
        <div class="pt-4 consent flex col-12 lg:col-6">
          <Checkbox  :binary="true"  v-model="isConsent" class="mr-2"/>
          <div>I consent to <span class="consent-text">Electronic Records & Signatures</span></div>
        </div>
        </div>
      </div>
      <template #footer>
        <Button label="Cancel" @click="isOpen = false" class="p-button-outlined p-button-lg"/>
        <Button :disabled="!isConsent" label="Start Signing" @click="start()" class="p-button-lg lg:px-12"/>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';

export default {
  name: 'SignaturesStart',
  components: {
    Dialog,
    Button,
    Checkbox
  },
  updated() {
    this.isOpen = !!this.contact;
  },
  props: {
    contact: Object,
  },

  methods: {
    hide() {
        this.isConsent = false;
        this.$emit('close');
    },
    start() {
      this.$emit('start', this.contact.id);
    },
  },
  data() {
    return {
      isOpen: false,
      isConsent: false,
    };
  },
};
</script>