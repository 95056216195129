<template>
  <full-screen-modal-with-header :init-data="{e_commerce: true, card_present_transaction: 0}"
                                 v-slot:default="props" v-if="this.isActive" :active="this.isActive" :merchant-number="this.application.merchant_number"
                                 title="Merchant Application" prev="business" current="profile" next="banking">
    <div id="new-merchant-app-2">
      <div class="grid m-0 flex flex-1">
        <div class="p-0 col-12 lg:hidden">
          <div class="step-description step-description-mobile flex flex-column lg:hidden">
            <div class="flex justify-content-between align-items-center">
              <div class="step flex mb-0">
                <div class="step-number step-number-active">2</div>
                <div class="step-name">Merchant Profile</div>
              </div>
              <div class="step-header">Step 3 of 5</div>
            </div>
            <p class="gray-text mb-0">Let’s build your merchant profile. Tell us about your sales and
              processing needs.</p>
          </div>
        </div>
        <div class="hidden lg:flex lg:col-3 p-0">
          <div class="step-description hidden lg:flex">
            <div>
              <div class="step-header mb-2">Step 3 of 5</div>
              <p class="gray-text mt-2 mb-5">Let’s build your merchant profile. Tell us about your sales
                and processing needs.</p>
              <FormSteps
                  :completed_forms="this.application.completed_steps.completed_forms"
                  current="profile"
                  @open="this.$emit('open', $event)"
              />
            </div>
          </div>
        </div>
        <div class="lg:col-9 col-12 px-0">
          <div class="step-body mt-2 lg:mt-0">
            <div class="step-header mb-5 px-0 lg:px-2">
              To accurately build your profile, please provide us with the following sales information.
            </div>
            <div class="grid mx-0 mb-4">
              <div class="col-12 md:col-6 lg:col-4 px-0 lg:px-2">
                <div class="flex flex-column p-field">
                  <label for="annualSalesVolume" class="mb-2">Annual Sales Volume</label>
                  <div class="p-inputgroup mb-2">
                                    <span class="p-inputgroup-addon">
                                        <i class="pi pi-dollar"/>
                                    </span>
                    <InputText
                        id="annualSalesVolume"
                        placeholder="Example: 60000"
                        type="number"
                        :disabled="props.params.loading"
                        v-model.number="props.data.annual_volume"
                        :class="{'p-invalid': props.params.errors.annual_volume }"
                        aria-describedby="annual_volume_help"
                    />
                  </div>
                  <small id="annual_volume_help" v-if="props.params.errors.annual_volume" class="p-error">{{ props.params.errors.annual_volume[0] }}</small>
                </div>
              </div>
              <div class="col-12 md:col-6 lg:col-4 px-0 lg:px-2">
                <div class="flex flex-column p-field">
                  <label for="averageTicket" class="mb-2">Average Ticket Price</label>
                  <div class="p-inputgroup mb-2">
                                    <span class="p-inputgroup-addon">
                                        <i class="pi pi-dollar"/>
                                    </span>
                    <InputText
                        id="averageTicket"
                        placeholder="Example: 2500"
                        type="number"
                        v-model.number="props.data.average_ticket"
                        :disabled="props.params.loading"
                        :class="{'p-invalid': props.params.errors.average_ticket }"
                        aria-describedby="average_ticket_help"
                    />
                  </div>
                  <small id="average_ticket" v-if="props.params.errors.average_ticket" class="p-error">{{ props.params.errors.average_ticket[0] }}</small>
                </div>
              </div>
              <div class="col-12 md:col-6 lg:col-4 px-0 lg:px-2">
                <div class="flex flex-column p-field">
                  <label for="hightTicket" class="mb-2">High Ticket Price</label>
                  <div class="p-inputgroup mb-2">
                                    <span class="p-inputgroup-addon">
                                        <i class="pi pi-dollar"/>
                                    </span>
                    <InputText
                        id="hightTicket"
                        placeholder="Example: 10000"
                        type="number"
                        v-model.number="props.data.high_ticket"
                        :disabled="props.params.loading"
                        :class="{'p-invalid': props.params.errors.high_ticket }"
                        aria-describedby="high_ticket_help"
                    />
                  </div>
                  <small id="high_ticket_help" v-if="props.params.errors.high_ticket" class="p-error">{{ props.params.errors.high_ticket[0] }}</small>
                </div>
              </div>
            </div>

            <div class="grid mx-0 mb-5">
              <div class="col-12 lg:col-4 px-0 lg:px-2">
                <div class="step-header mb-2">Card Present Transactions</div>
                <div class="gray-text">Percentage of sales completed with a card present at purchase.
                </div>
              </div>
              <div class="col-12 lg:col-8 flex px-0 lg:px-2">
                <div id="slabel">{{ props.data.card_present_transaction }}%</div>
                <div class="flex flex-1 align-items-center">
                  <div class="w-full">
                    <Slider :disabled="props.params.loading || props.data.e_commerce" v-model.number="props.data.card_present_transaction"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="grid mx-0 mb-3">
              <div class="col-12 lg:col-4 px-0 lg:px-2">
                <div class="step-header mb-2">Card Not Present Transactions</div>
                <div class="gray-text">Percentage of sales that are done online, via phone or mail, etc.
                </div>
              </div>
              <div class="col-12 lg:col-8 flex px-0 lg:px-2">
                <div class="flex flex-1 align-items-center">
                  <div class="w-full">
                    <Slider :disabled="initSlider(props)" @change="props.data.card_present_transaction = 100 - cardNotPresentTransaction" v-model.number="cardNotPresentTransaction"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-column mb-4 px-0 lg:px-2">
              <p>Is your business primarily e-commerce?</p>
              <div class="flex">
                <div class="p-field-radiobutton mr-4">
                  <RadioButton id="e_commerce_yes" v-on:change="props.data.card_present_transaction = ecommerceChange(props.data.e_commerce, props.data.card_present_transaction)" :disabled="props.params.loading" :value="true" v-model="props.data.e_commerce"/>
                  <label for="e_commerce_yes">Yes</label>
                </div>
                <div class="p-field-radiobutton">
                  <RadioButton id="e_commerce_no" v-on:change="props.data.card_present_transaction = ecommerceChange(props.data.e_commerce, props.data.card_present_transaction)" :disabled="props.params.loading" :value="false" v-model="props.data.e_commerce"/>
                  <label for="e_commerce_no">No</label>
                </div>
              </div>
            </div>
            <div class="grid mx-0 mb-3">
              <div class="col-12 lg:col-6 px-0 lg:px-2">
                <div class="p-field mb-2 flex flex-column">
                  <label for="refundPolicy" class="mb-2">Return/Refund Policy</label>
                  <InputText
                      id="refundPolicy"
                      placeholder="Example: 30 days"
                      type="text"
                      v-model="props.data.refund_policy"
                      :disabled="props.params.loading"
                      :class="{'p-invalid': props.params.errors.refund_policy }"
                      aria-describedby="refund_policy_help"
                  />
                  <small id="refund_policy_help" v-if="props.params.errors.refund_policy" class="p-error">{{ props.params.errors.refund_policy[0] }}</small>
                </div>
              </div>
              <div class="col-12 lg:col-6 px-0 lg:px-2">
                <div class="p-field mb-2 flex flex-column">
                  <label for="productsSoldType" class="mb-2">Type of Products Sold</label>
                  <InputText
                      id="productsSoldType"
                      placeholder="Example: Food"
                      type="text"
                      v-model="props.data.type_of_products"
                      :disabled="props.params.loading"
                      :class="{'p-invalid': props.params.errors.type_of_products }"
                      aria-describedby="type_of_products_help"
                  />
                  <small id="type_of_products_help" v-if="props.params.errors.type_of_products" class="p-error">{{ props.params.errors.type_of_products[0] }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </full-screen-modal-with-header>
</template>

<script>
import FullScreenModalWithHeader from '@/components/FullScreenModalWithHeader';
import InputText from 'primevue/inputtext';
import Slider from 'primevue/slider';
import RadioButton from 'primevue/radiobutton';
import FormSteps from './FormSteps';

export default {
  components: {
    FullScreenModalWithHeader,
    InputText,
    Slider,
    RadioButton,
    FormSteps
  },
  methods: {
    initSlider(props) {
      this.cardNotPresentTransaction = 100 - props.data.card_present_transaction

      return props.params.loading || props.data.e_commerce;
    },
    ecommerceChange(value, presentTransaction) {
        if (value) {
            return 0;
        }
        return presentTransaction;
    }
  },
  props: {
    application: Object,
    activeForm: String,
    isActive: Boolean,
  },
  data() {
    return {
      cardNotPresentTransaction: 0
    };
  },
};
</script>